.wp-block-rss {
  padding-left: 0;

  > li {
    list-style: none;
  }

  // Vertical margins logic
  &:not(.is-grid) > li {
    margin-top: calc(1.666 * var(--global--spacing-vertical));
    margin-bottom: calc(1.666 * var(--global--spacing-vertical));

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-grid {
    > li {
      margin-bottom: var(--global--spacing-vertical);

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Remove bottom margins in grid columns
    &.columns-2 > li:nth-last-child(-n + 2):nth-child(2n + 1),
    &.columns-2 > li:nth-last-child(-n + 2):nth-child(2n + 1) ~ li,
    &.columns-3 > li:nth-last-child(-n + 3):nth-child(3n + 1),
    &.columns-3 > li:nth-last-child(-n + 3):nth-child(3n + 1) ~ li,
    &.columns-4 > li:nth-last-child(-n + 4):nth-child(4n + 1),
    &.columns-4 > li:nth-last-child(-n + 4):nth-child(4n + 1) ~ li,
    &.columns-5 > li:nth-last-child(-n + 5):nth-child(5n + 1),
    &.columns-5 > li:nth-last-child(-n + 5):nth-child(5n + 1) ~ li,
    &.columns-6 > li:nth-last-child(-n + 6):nth-child(6n + 1),
    &.columns-6 > li:nth-last-child(-n + 6):nth-child(6n + 1) ~ li {
      margin-bottom: 0;
    }
  }

  > li > * {
    margin-top: calc(0.333 * var(--global--spacing-vertical));
    margin-bottom: calc(0.333 * var(--global--spacing-vertical));

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Post title
  .wp-block-rss__item-title > a {
    display: inline-block;
    font-family: var(--latest-posts--title-font-family);
    font-size: var(--latest-posts--title-font-size);
    font-weight: var(--heading--font-weight);
    line-height: var(--global--line-height-heading);
    margin-bottom: calc(0.333 * var(--global--spacing-vertical));
  }

  // Post author
  .wp-block-rss__item-author {
    color: var(--global--color-primary);
    font-size: var(--global--font-size-md);
    line-height: var(--global--line-height-body);
  }

  // Post date
  .wp-block-rss__item-publish-date {
    color: var(--global--color-primary);
    font-size: var(--global--font-size-xs);
    line-height: var(--global--line-height-body);

    [class*="inner-container"] &,
    .has-background & {
      color: currentColor;
    }
  }

  // Post content
  .wp-block-rss__item-excerpt,
  .wp-block-rss__item-full-content {
    font-family: var(--latest-posts--description-font-family);
    font-size: var(--latest-posts--description-font-size);
    line-height: var(--global--line-height-body);
    margin-top: calc(0.666 * var(--global--spacing-vertical));
  }

  // Utility classes
  &.alignfull {
    padding-left: var(--global--spacing-unit);
    padding-right: var(--global--spacing-unit);

    .entry-content [class*="inner-container"] &,
    .entry-content .has-background & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
