/*
Adding print support. The print styles are based on the the great work of
Andreas Hecht in https://www.jotform.com/blog/css-perfect-print-stylesheet-98272/.
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Margins & paddings
# Typography
# Page breaks
# Links
# Visibility
--------------------------------------------------------------*/
@media print {
  /* Margins & paddings */
  @page {
    margin: 2cm;
  }

  .entry .entry-header,
  .entry,
  .single .site-main > article > .entry-footer {
    margin-top: 0;
    margin-bottom: 0;
  }

  .site-footer .site-info {
    margin: 0;
  }

  /* Fonts */
  body {
    font: 13pt Georgia, "Times New Roman", Times, serif;
    font: 13pt
      var(--global--font-secondary, Georgia, "Times New Roman", Times, serif);
    line-height: 1.3;
    background: #fff !important;
    color: #000;
  }

  .has-background-dark * {
    color: #000 !important;
  }

  h1,
  .entry-title,
  .singular .entry-title,
  .page-title {
    font-size: 22pt;
    font-weight: bold;
  }

  h2,
  h3,
  h4,
  .has-regular-font-size,
  .has-large-font-size,
  h2.author-title,
  p.author-bio,
  .comments-title,
  .archive-description {
    font-size: 14pt;
    margin-top: 25px;
  }

  .comment-meta,
  .comment-meta .comment-author .fn {
    font-size: 13pt;
  }

  /* Page breaks */
  a {
    page-break-inside: avoid;
  }

  blockquote {
    page-break-inside: avoid;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  table,
  pre,
  figure {
    page-break-inside: avoid;
  }

  ul,
  ol,
  dl {
    page-break-before: avoid;
  }

  /* Links */
  a:link,
  a:visited,
  a {
    background: transparent;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
  }

  a[href^="http"]:after {
    content: " < " attr(href) "> ";
  }

  a:after > img {
    content: "";
  }

  article a[href^="#"]:after {
    content: "";
  }

  a:not(:local-link):after {
    content: " < " attr(href) "> ";
  }

  .entry-title a:after {
    content: "\a< "attr(href) "> ";
    white-space: pre;
    font-size: 14pt;
  }

  .cat-links a:after,
  .tags-links a:after,
  .byline a:after,
  .comment-metadata a:after,
  .wp-block-calendar a:after,
  .wp-block-tag-cloud a:after,
  .page-links a:after {
    content: "";
  }

  /* Visibility */
  .footer-navigation,
  .entry-footer,
  .post-navigation,
  .navigation.pagination,
  .widget-area,
  .edit-link,
  .more-link,
  .comment-reply,
  .reply,
  .comment .comment-metadata .edit-link,
  .comment-respond,
  #dark-mode-toggler {
    display: none !important;
  }

  .entry .entry-content .wp-block-button .wp-block-button__link,
  .entry .entry-content .button,
  .entry .entry-content .wp-block-file__button {
    color: #000;
    background: none;
  }
}
