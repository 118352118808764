.heading {
  color: var(--global--color-gold);
}

.signUpDescription {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
  color: #fff;
  font-weight: 400;

  a {
    color: var(--global--color-gold);
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.mainFormWrapper {
  width: 100%;
}

.mainFormTop {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.input {
  width: 100%;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  color: white;
  background-color: transparent;
  border: 1px solid #808080;
  padding: 18px 22px;
  background-color: black;
  outline: none;
  height: 50px;

  @media screen and (max-width: 767px) {
    font-size: 12px !important;
  }
}

.signUpButton {
  font-size: 18px !important;
}

.emailOptInWrapper {
  display: flex;
  margin: 20px 0;
}

input[type=checkbox] + .checkboxLabel {
  font-size: 13px;
  font-weight: 400;
  align-items: flex-start;
  color: white;
  line-height: 15.51px;

  &:before {
    border: 1px solid white;
    margin-top: 4px;
  }
  &.whiteBgText {
    &:before {
      border: 1px solid black;
    }
  }
}

.checkboxError + label:before {
  border-color: #ad343e !important;
}

.whiteBgInput {
  background-color: #E8E0C4 !important;
  border-color: #808080 !important;
  color: var(--global--color-focus-tertiary) !important;
}

.whiteBgText {
  color: var(--global--color-focus-tertiary) !important;
}

.errorText {
  display: block;
  margin-top: 5px;
  color: #ad343e;
}
