// Gutenberg Font-size utility classes
:root {
  .is-extra-small-text,
  .has-extra-small-font-size {
    font-size: var(--global--font-size-xs);
  }

  .is-small-text,
  .has-small-font-size {
    font-size: var(--global--font-size-sm);
  }

  .is-regular-text,
  .has-regular-font-size,
  .is-normal-font-size,
  .has-normal-font-size,
  .has-medium-font-size {
    font-size: var(--global--font-size-base);
  }

  .is-large-text,
  .has-large-font-size {
    font-size: var(--global--font-size-lg);
    line-height: var(--global--line-height-heading);
  }

  .is-larger-text,
  .has-larger-font-size,
  .is-extra-large-text,
  .has-extra-large-font-size {
    font-size: var(--global--font-size-xl);
    line-height: var(--global--line-height-heading);
  }

  .is-huge-text,
  .has-huge-font-size {
    font-size: var(--global--font-size-xxl);
    line-height: var(--global--line-height-heading);

    // This size is meant to mimic the page titles, so the font weight is reduced to match.
    font-weight: var(--heading--font-weight-page-title);
  }

  .is-gigantic-text,
  .has-gigantic-font-size {
    font-size: var(--global--font-size-xxxl);
    line-height: var(--global--line-height-heading);

    // This size is meant to mimic the page titles, so the font weight is reduced to match.
    font-weight: var(--heading--font-weight-page-title);
  }
}
