.body-div:not(.sticky-menu).nav-open {
  .site-header-block {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--global--color-white);
    z-index: 999;
  }
}

.scroll-lock {
  position: fixed;
  overflow-y: hidden;
}

.body-div,
html {
  transition: 0.5s all;
}
