.instafeed-title {
  font-size: 37px;
  font-family: "AktivGrotesk Ex Black Italic";
  text-transform: uppercase;
  color: var(--global--color-focus-tertiary);
  text-align: center;
  margin-top: 100px;
  margin-bottom: 35px;
  letter-spacing: -2.2px;
  a {
    color: var(--global--color-focus-tertiary);
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1199px) {
    margin-top: 60px;
    margin-bottom: 0px;
    font-size: var(--global--font-size-lg);
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: var(--global--font-size-md);
  }
}

.instafeed-list {
  margin-bottom: 90px;
  .wp-block-column {
    margin: 30px 20px 0 20px !important;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    p {
      &:empty {
        display: none;
      }
    }

    .wp-block-image {
      a {
        &:focus {
          img {
            outline: none;
          }
        }
      }

      img {
        width: 100%;
      }

      figcaption {
        text-align: left;
        margin: 30px 0 0 0;

        a {
          text-transform: uppercase;
          font-family: "AktivGrotesk Std Bold";
          letter-spacing: 6px;
          font-style: normal;
          font-size: 12px;
          letter-spacing: 6.7px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .wp-block-column {
      margin: 30px 10px 0 10px !important;

      .wp-block-image {
        margin: 0;

        figcaption {
          margin: 20px 0 0 0;

          a {
            font-size: 10px;
            letter-spacing: 3.5px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin: 0 -5px 70px !important;
    letter-spacing: 1.5px;
  }

  @media screen and (max-width: 781px) {
    margin: 0 -10px 70px !important;
    .wp-block-column {
      flex-basis: calc(50% - 30px) !important;
      margin: 30px 15px 0 15px !important;

      &:first-child {
        margin-left: 15px !important;
      }

      &:last-child {
        margin-right: 15px !important;
      }
    }

    .wp-block-column .wp-block-image figcaption a {
      font-size: 10px;
      letter-spacing: 4px;
    }
  }

  @media screen and (max-width: 499px) {
    margin: 0 -10px 50px !important;

    .wp-block-column {
      flex-basis: calc(50% - 20px) !important;
      margin: 20px 10px 0 10px !important;

      &:first-child {
        margin-left: 10px !important;
      }

      &:last-child {
        margin-right: 10px !important;
      }
    }

    .wp-block-column .wp-block-image figcaption a {
      letter-spacing: 1px;
    }
  }
}
