.post-with-filter {
  h2 {
    text-align: center;
    font-size: 60.7593px;
    line-height: 59px;
    color: var(--global--color-white);
    padding-top: 82px;
    margin-bottom: 54px;
    letter-spacing: -3.5px;
  }
}

.filter-list {
  ul {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 677px;
    margin: 0 auto 56px;
    justify-content: space-between;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: $med-gray;
      z-index: 0;
    }
  }

  li {
    list-style-type: none;
    border-bottom: 3px solid $med-gray;
    z-index: 2;
  }

  a {
    display: block;
    font-size: 15.1304px;
    line-height: 34px;
    color: $med-gray;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: bold;
    padding: 0 30px;
    text-align: center;
  }

  .active-filter {
    border-bottom: 4px solid var(--global--color-focus-tertiary);

    a {
      color: var(--global--color-focus-tertiary);
    }
  }
}

.filter-contents {
  display: flex;
  flex-wrap: wrap;

  .filter-content {
    display: flex;
    flex-direction: column;
    display: none;
    color: #fff;
    position: relative;
    width: 20%;
    text-align: center;
    padding: 15px;
    border: 1px solid #fff;
    border-right: none;

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      width: 1px;
      background-color: #fff;
    }

    &.active-content {
      display: flex;
    }

    .feature-img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .feature-title-block {
      border-bottom: 1px dashed#FFFFFF;
      margin-bottom: 20px;

      span {
        font-size: 10px;
        line-height: 20px;
        letter-spacing: 4px;
        text-transform: uppercase;
        letter-spacing: 7px;
      }

      h3 {
        font-size: 26.7236px;
        line-height: 26px;
        font-family: "AktivGrotesk Ex Black Italic";
        color: var(--global--color-focus-tertiary);
        margin: 2px 0 20px;
        max-width: 240px;
        letter-spacing: -1px;
      }
    }
  }
}

.feature-item-details {
  flex-grow: 1;
  margin-top: 20px;
  position: relative;

  p:empty {
    display: none;
    visibility: hidden;
  }

  p {
    font-size: 10px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    margin-bottom: 40px;
    max-width: 300px;
  }
}

.music-feature-button {
  font-size: 12px !important;
}

.feature-btn {
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-color: #fff;
  color: #fff;
  min-width: 140px;
  padding: 7px 20px;
  font-family: "AktivGrotesk Ex Black Italic";
  text-transform: uppercase;
  width: max-content;
  
  &.focus-text {
    font-size: 13px;
  }

  &:hover {
    background-color: #fff;
    color: #000;
  }

}

[data-info="section-0"].filter-content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  text-align: left;
  border: 1px solid #fff;
  border-right: none;
  width: calc(100% - 1px);
  height: 540px;
  flex-wrap: wrap;

  .feature-title-block {
    position: relative;
    width: 100%;
    margin-bottom: 0;
    border-bottom: 0;
    z-index: 2;
    padding-left: calc(63% + 40px);
  }

  &::before {
    display: none;
  }

  .feature-item-details {
    position: relative;
    width: 100%;
    padding-left: calc(63% + 40px);
    flex-grow: initial;

    p {
      font-size: 12.4267px;
      line-height: 19px;
      max-width: 385px;
      margin-bottom: 50px;
    }
  }

  .feature-title-block h3 {
    font-size: 36.996px;
    line-height: 36px;
    margin-bottom: 0;
    max-width: 100%;
    letter-spacing: -2.5px;
  }

  .feature-img {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 508px;
    width: 62%;
  }
}

[data-info="section-1"].filter-content,
[data-info="section-3"].filter-content,
[data-info="section-6"].filter-content,
[data-info="section-8"].filter-content,
[data-info="section-9"].filter-content,
[data-info="section-11"].filter-content,
[data-info="section-14"].filter-content,
[data-info="section-16"].filter-content {
  width: 33%;
  text-align: left;
  margin-top: -1px;
}

[data-info="section-2"].filter-content,
[data-info="section-4"].filter-content,
[data-info="section-5"].filter-content,
[data-info="section-7"].filter-content,
[data-info="section-10"].filter-content,
[data-info="section-12"].filter-content,
[data-info="section-13"].filter-content,
[data-info="section-15"].filter-content {
  width: 17%;
  text-align: left;
  margin-top: -1px;
}

[data-info="section-1"].filter-content .feature-img,
[data-info="section-3"].filter-content .feature-img,
[data-info="section-6"].filter-content .feature-img,
[data-info="section-8"].filter-content .feature-img,
[data-info="section-9"].filter-content .feature-img,
[data-info="section-11"].filter-content .feature-img,
[data-info="section-14"].filter-content .feature-img,
[data-info="section-16"].filter-content .feature-img {
  height: 277px;
  width: 100%;
}

[data-info="section-2"].filter-content .feature-img,
[data-info="section-4"].filter-content .feature-img,
[data-info="section-5"].filter-content .feature-img,
[data-info="section-7"].filter-content .feature-img,
[data-info="section-10"].filter-content .feature-img,
[data-info="section-12"].filter-content .feature-img,
[data-info="section-13"].filter-content .feature-img,
[data-info="section-15"].filter-content .feature-img {
  height: 209px;
  width: 100%;
  order: 0;
}

[data-info="section-2"].filter-content .feature-title-block,
[data-info="section-4"].filter-content .feature-title-block,
[data-info="section-5"].filter-content .feature-title-block,
[data-info="section-7"].filter-content .feature-title-block,
[data-info="section-10"].filter-content .feature-title-block,
[data-info="section-12"].filter-content .feature-title-block,
[data-info="section-13"].filter-content .feature-title-block,
[data-info="section-15"].filter-content .feature-title-block {
  order: 1;
  margin-top: 15px;
}

[data-info="section-2"].filter-content .feature-item-details,
[data-info="section-4"].filter-content .feature-item-details,
[data-info="section-5"].filter-content .feature-item-details,
[data-info="section-7"].filter-content .feature-item-details,
[data-info="section-10"].filter-content .feature-item-details,
[data-info="section-12"].filter-content .feature-item-details,
[data-info="section-13"].filter-content .feature-item-details,
[data-info="section-15"].filter-content .feature-item-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: 2;
  margin-top: 0;
}

[data-info="section-1"].filter-content .feature-item-details,
[data-info="section-3"].filter-content .feature-item-details,
[data-info="section-6"].filter-content .feature-item-details,
[data-info="section-8"].filter-content .feature-item-details,
[data-info="section-9"].filter-content .feature-item-details,
[data-info="section-11"].filter-content .feature-item-details,
[data-info="section-14"].filter-content .feature-item-details,
[data-info="section-16"].filter-content .feature-item-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  p {
    align-self: flex-start;
  }
}

[data-info="section-1"].filter-content p,
[data-info="section-3"].filter-content p,
[data-info="section-6"].filter-content p,
[data-info="section-8"].filter-content p,
[data-info="section-9"].filter-content p,
[data-info="section-11"].filter-content p,
[data-info="section-14"].filter-content p,
[data-info="section-16"].filter-content p {
  margin-bottom: 4px;
  padding-right: 15px;
}

@media screen and (max-width: 1440px) {
  [data-info="section-0"].filter-content .feature-product-details {
    padding: 0 15px 0 30px;
  }

  [data-info="section-1"].filter-content .feature-item-details,
  [data-info="section-3"].filter-content .feature-item-details,
  [data-info="section-6"].filter-content .feature-item-details,
  [data-info="section-8"].filter-content .feature-item-details,
  [data-info="section-9"].filter-content .feature-item-details,
  [data-info="section-11"].filter-content .feature-item-details,
  [data-info="section-14"].filter-content .feature-item-details,
  [data-info="section-16"].filter-content .feature-item-details {
    flex-wrap: wrap;

    p {
      align-self: flex-start;
      margin-bottom: 25px;
    }
  }

  [data-info="section-1"].filter-content,
  [data-info="section-3"].filter-content,
  [data-info="section-6"].filter-content,
  [data-info="section-8"].filter-content,
  [data-info="section-9"].filter-content,
  [data-info="section-11"].filter-content,
  [data-info="section-14"].filter-content,
  [data-info="section-16"].filter-content {
    width: 30%;
  }

  [data-info="section-2"].filter-content,
  [data-info="section-4"].filter-content,
  [data-info="section-5"].filter-content,
  [data-info="section-7"].filter-content,
  [data-info="section-10"].filter-content,
  [data-info="section-12"].filter-content,
  [data-info="section-13"].filter-content,
  [data-info="section-15"].filter-content {
    width: 20%;
  }
}

@media screen and (max-width: 1199px) {
  .post-with-filter h2 {
    font-size: 42px;
  }

  [data-info="section-0"].filter-content {
    width: 100%;
  }

  .filter-contents .filter-content .feature-title-block h3 {
    font-size: 21px;
    line-height: 26px;
  }

  .filter-contents
    [data-info="section-0"].filter-content
    .feature-title-block
    h3 {
    font-size: 32px;
  }

  .feature-item-details p {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .filter-contents [data-info="section-0"].filter-content {
    height: auto;

    .feature-img {
      position: relative;
      left: auto;
      top: auto;
      width: 100%;
      order: 1;
    }

    .feature-item-details {
      padding-left: initial;
      order: 3;
    }

    .feature-title-block {
      padding-left: initial;
      margin-top: 15px;
      order: 2;
    }

    .feature-item-details p {
      margin-bottom: 30px;
      max-width: 100%;
    }
  }

  [data-info="section-1"].filter-content,
  [data-info="section-3"].filter-content,
  [data-info="section-6"].filter-content,
  [data-info="section-8"].filter-content,
  [data-info="section-9"].filter-content,
  [data-info="section-11"].filter-content,
  [data-info="section-14"].filter-content,
  [data-info="section-16"].filter-content {
    width: 65%;
  }

  [data-info="section-2"].filter-content,
  [data-info="section-4"].filter-content,
  [data-info="section-5"].filter-content,
  [data-info="section-7"].filter-content,
  [data-info="section-10"].filter-content,
  [data-info="section-12"].filter-content,
  [data-info="section-13"].filter-content,
  [data-info="section-15"].filter-content {
    width: 35%;
  }

  [data-info="section-1"].filter-content .feature-item-details,
  [data-info="section-3"].filter-content .feature-item-details,
  [data-info="section-6"].filter-content .feature-item-details,
  [data-info="section-8"].filter-content .feature-item-details,
  [data-info="section-9"].filter-content .feature-item-details,
  [data-info="section-11"].filter-content .feature-item-details,
  [data-info="section-14"].filter-content .feature-item-details,
  [data-info="section-16"].filter-content .feature-item-details {
    display: flex;
    align-items: initial;
    .feature-btn {
      height: max-content;
      align-self: flex-end;
    }
  }

  .feature-item-details {
    margin-bottom: 5px;
  }

  .filter-contents .filter-content .feature-title-block h3 {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .filter-contents .filter-content {
    width: 100%;

    .feature-title-block {
      margin-top: 0;
      order: 1;
    }

    .feature-item-details {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      order: 3;

      p {
        margin-bottom: 0;
        padding-right: 15px;
        max-width: calc(100% - 140px);
      }
    }

    .feature-btn {
      height: max-content;
      align-self: flex-end;
    }

    .feature-img {
      order: 2;
      height: 234px;
    }
  }

  [data-info="section-2"].filter-content,
  [data-info="section-3"].filter-content {
    width: 50%;

    .feature-title-block {
      margin-top: 15px;
      order: 2;
    }

    .feature-img {
      order: 1;
    }

    .feature-item-details {
      flex-wrap: wrap;
      order: 3;
      margin-top: 0;

      p {
        margin-bottom: 20px;
        padding-right: 0;
        max-width: 100%;
      }
    }
  }

  // .filter-contents [data-info="section-3"].filter-content,
  // .filter-contents [data-info="section-5"].filter-content,
  // .filter-contents [data-info="section-7"].filter-content,
  // .filter-contents [data-info="section-8"].filter-content {
  //     display: none ;
  // }

  .post-with-filter h2 {
    font-size: 30px;
    line-height: 29px;
    padding-top: 45px;
    letter-spacing: -2px;
    margin-bottom: 30px;
    max-width: 320px;
    margin: 0 auto 30px;
  }

  .filter-list ul {
    margin-bottom: 50px;

    &::after {
      height: 2px;
    }
  }

  .filter-list li {
    border-width: 2px;
    padding: 0 18px;
    display: flex;
    justify-content: center;
  }

  .filter-list a {
    font-size: 11px;
    padding: 0 18px;
    line-height: 20px;
  }

  .filter-contents
    [data-info="section-0"].filter-content
    .feature-item-details {
    display: block;

    p {
      max-width: 100%;
    }
  }

  [data-info="section-0"].filter-content .feature-img {
    height: 474px;
  }

  .filter-contents .filter-content .feature-product-details p {
    font-size: 9px;
    line-height: 17px;
    max-width: 310px;
  }

  .feature-btn {
    min-width: 120px;
    padding: 3px 10px;
  }
}

@media only screen and (max-width: 499px) {
  .filter-contents .filter-content .feature-item-details {
    display: block;

    p {
      max-width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }
  }

  [data-info="section-2"].filter-content,
  [data-info="section-3"].filter-content {
    width: 100%;

    .feature-title-block {
      margin-top: 0;
      order: 1;
    }

    .feature-img {
      order: 2;
    }

    .feature-item-details {
      order: 3;
      margin-top: 20px;
    }
  }

  .feature-item-details p {
    margin-bottom: 25px;
  }

  .filter-list a {
    padding: 0 8px;
  }
}
