p {
  line-height: var(
    --wp--typography--line-height,
    var(--global--line-height-body)
  );

  // inherits general font style set at <body>
  &.has-background {
    padding: var(--global--spacing-unit);
  }

  // Override `color: inherit` from Core styles.
  &.has-text-color a {
    color: var(--wp--style--color--link, var(--global--color-primary));
  }
}
