/**
 * Comments Wrapper
 */
.comments-area {
  > * {
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.show-avatars {
    .avatar {
      border-radius: 50%;
      position: absolute;
      top: 10px;
    }

    .fn {
      display: inline-block;
      padding-left: 85px;
    }

    .comment-metadata {
      padding: 8px 0 9px 85px;
    }
  }
}

/**
 * Comment Title
 */

.comments-title,
.comment-reply-title {
  font-size: var(--heading--font-size-h2);
  letter-spacing: var(--heading--letter-spacing-h2);
}

.comment-reply-title {
  display: flex;
  justify-content: space-between;

  small {
    a {
      font-family: var(--global--font-secondary);
      font-size: var(--global--font-size-xs);
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
    }
  }
}

/* Nested comment reply title*/
.comment .comment-respond .comment-reply-title {
  font-size: var(--global--font-size-lg);
}

/**
 * Comment Lists
 */
.comment-list {
  padding-left: 0;
  list-style: none;

  > li {
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);
  }
}

.comment-list .children {
  list-style: none;
  padding-left: 0;

  > li {
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);
  }
}

.comment-list .depth-2,
.comment-list .depth-3 {
  @include media(mobile) {
    padding-left: calc(4 * var(--global--spacing-horizontal));
  }
}

/**
 * Comment Meta
 */
.comment-meta {
  .comment-author {
    line-height: var(--global--line-height-heading);
    margin-bottom: calc(0.25 * var(--global--spacing-unit));

    @include media(mobile) {
      margin-bottom: 0;
      padding-right: 0;
    }

    .fn {
      font-family: var(--global--font-secondary);
      font-weight: normal;
      font-size: var(--global--font-size-lg);
      hyphens: auto;
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  .comment-metadata {
    color: var(--global--color-primary);
    font-size: var(--global--font-size-xs);
    padding: 8px 0 9px 0;

    .edit-link {
      margin-left: var(--global--spacing-horizontal);
    }
  }

  @include media(mobile) {
    margin-right: inherit;

    .comment-author {
      max-width: inherit;
    }
  }
}

.reply {
  font-size: var(--global--font-size-sm);
  line-height: var(--global--line-height-heading);
}

.bypostauthor {
  display: block;
}

.says {
  display: none;
}

.pingback .url,
.trackback .url {
  font-family: var(--global--font-primary);
}

// Comment body
.comment-body {
  position: relative;
  margin-bottom: calc(1.7 * var(--global--spacing-vertical));

  > * {
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);
  }

  .reply {
    margin: 0;
  }
}

.comment-content {
  word-wrap: break-word;
}

// Pingbacks & Trackbacks
.pingback .comment-body,
.trackback .comment-body {
  margin-top: var(--global--spacing-vertical);
  margin-bottom: var(--global--spacing-vertical);
}

.comment-respond {
  margin-top: var(--global--spacing-vertical);
}

.comment-respond > * {
  margin-top: var(--global--spacing-unit);
  margin-bottom: var(--global--spacing-unit);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;

    &.comment-form {
      margin-bottom: var(--global--spacing-vertical);
    }
  }
}

.comment-author {
  padding-top: 3px;

  .url {
    color: currentColor;
  }
}

.comment-form {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex-basis: 100%;
  }

  .comment-notes {
    font-size: var(--global--font-size-sm);
  }

  .comment-form-url,
  .comment-form-comment {
    width: 100%;
  }

  .comment-form-author,
  .comment-form-email {
    flex-basis: 0;
    flex-grow: 1;

    @include media(mobile-only) {
      flex-basis: 100%;
    }
  }

  .comment-form-cookies-consent > label,
  .comment-notes {
    font-size: var(--global--font-size-xs);
    font-weight: normal;
  }
}

.comment-form > p {
  margin-bottom: var(--global--spacing-unit);

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  label,
  input[type="email"],
  input[type="text"],
  input[type="url"],
  textarea {
    display: block;
    font-size: var(--global--font-size-sm);
    margin-bottom: calc(0.5 * var(--global--spacing-unit));
    width: 100%;
    font-weight: var(--form--label-weight);
  }

  &.comment-form-cookies-consent {
    display: flex;
  }

  @include media(mobile) {
    &.comment-form-author {
      margin-right: calc(1.5 * var(--global--spacing-horizontal));
    }

    &.comment-notes,
    &.logged-in-as {
      display: block;
    }
  }
}
