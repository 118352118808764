.faq-select {
  position: relative;
  max-width: 522px;
  margin: 0 auto;

  &::after {
    content: "";
    position: absolute;
    justify-self: end;
    width: 16px;
    height: 9px;
    background-color: #fff;
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    z-index: 2;
    grid-area: select;
    right: 0;
    top: 47%;
    transform: translate(-15px, -50%);
    pointer-events: none;
  }

  select {
    display: none !important;
  }

  .select2-container--default {
    .selection,
    .select2-selection--single,
    .select2-selection__rendered {
      display: inline-block;
      width: 100%;
    }

    .select2-selection__rendered {
      padding: 8px 0 8px 18px;
      white-space: nowrap;
    }

    .selection {
      display: inline-flex;
    }

    .select2-selection--single {
      display: flex;
      background-color: transparent;
      border: none !important;
    }

    .select2-selection__rendered {
      color: #fff;
      max-width: calc(100% - 40px);
      overflow: hidden;
    }

    .select2-selection__arrow {
      display: none;
    }
  }

  .select2-container--default {
    display: inline-block;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    border: 1px solid var(--global--color-white);
    border-radius: 35px !important;
    font-size: 16px;
    cursor: pointer;
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    color: #fff;
    font-family: "AktivGrotesk Ex Black Italic";
    text-transform: uppercase;
    background-image: none;
    overflow: hidden;
    max-width: 100%;
    text-align: left;

    &:focus {
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }

    option {
      color: #000;
    }
  }
}

.faq-selection {
  margin-bottom: 75px;
}

.faq-list {
  margin-bottom: 80px;
  display: none;
  
  &.isLightBg {
    background-color: #E8E0C4;
  }

  ul {
    .accordian {
      list-style-type: none;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: #fff;
      &.isLightBg {
        border-color: var(--global--color-focus-tertiary);
      }

      &:last-child {
        border-bottom: 1px solid #fff;
        &.isLightBg {
          border-color: var(--global--color-focus-tertiary);
        }
      }
    }
  }

  h2 {
    position: relative;
    font-size: 34px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 0;
    cursor: pointer;
    padding: 26px 70px 26px 35px;
    transition: 0.5s all;
    text-transform: uppercase;

    &.isLightBg {
      color: var(--global--color-focus-tertiary);
    }

    .accordion-plus,
    .accordion-min {
      position: absolute;
      right: 35px;
      top: 52%;
      transform: translateY(-50%);
      line-height: normal;
    }

    .accordion-min {
      display: none;
    }
  }

  .faq-description-wrapper {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
    &.full {
      max-height: 1000px;
      transition: max-height 0.5s ease-in-out;
    }
  }

  .faq-description {
    display: block;
    padding: 0px 35px 46px;
    margin-bottom: 0;
    font-family: 'AktivGrotesk Std Regular';
    color: var(--global--color-focus-tertiary);
    font-size: 16px;

    a {
      color: var(--global--color-focus-tertiary);
      text-decoration: underline;
    }

    ul {
      padding: 10px 20px !important;

      li {
        margin-bottom: 5px;
      }
    }
  }

  .activefaq {
    .accordion-plus {
      display: none;
    }

    .accordion-min {
      display: block;
    }

    h2 {
      transition: 0.5s all;
    }
  }
}

.locator-modal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  margin: 0;
  width: 100%;
  z-index: 999;
  max-width: 100% !important;
  margin: 0 !important;

  .locator-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .locator-modal-details {
    position: relative;
    width: 80%;
    max-width: 1200px;

    iframe {
      width: 100%;
      height: calc(100vh - 120px);
      background-color: #fff;
    }
  }

  .locator-modal-close {
    position: absolute;
    right: -45px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .faq-list {
    h2 {
      font-size: 28px;
      line-height: 28px;
    }

    .faq-description {
      padding-bottom: 38px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .faq-selection {
    margin-bottom: 55px;
  }

  .faq-list {
    h2 {
      font-size: 24px;
      line-height: 24px;
      padding: 20px 44px 20px 20px;
    }

    .faq-description {
      padding: 0px 20px 35px;
    }

    h2 .accordion-min,
    h2 .accordion-plus {
      right: 20px;
    }

    .activefaq {
      h2 {
        padding-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .faq-list {
    h2 {
      font-size: 21px;
      line-height: 21px;
      padding: 20px 45px 20px 20px;
    }
  }

  .locator-modal .locator-modal-details {
    width: 70%;
  }

  .locator-modal .locator-modal-close {
    right: -35px;
  }
}

@media only screen and (max-width: 500px) {
  .faq-list {
    h2 {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
