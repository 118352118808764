.btn {
  font-size: 16px;
  line-height: 22px;
  padding: 13px 20px 10px;
  border: 1px solid var(--global--color-focus-tertiary);
  color: var(--global--color-focus-tertiary);
  border-radius: 30px;
  display: inline-block;
  font-style: italic;
  font-family: "AktivGrotesk Ex Bold";
  transition: 0.3s all;

  &.btn-lg {
    font-size: 24px;
    line-height: 27px;
  }

  &:hover {
    transition: 0.3s all;
  }
}

.site-promo {
  display: flex;
  justify-content: center;
  margin: 0 40px;
  max-width: 100% !important;
  margin: auto;
  background-color: #2C2925;
  &.focus {
    max-width: 1360px;
    @media screen and (min-width: 1440px) {
      margin: auto;
    }
  }
  .widget_text {
    width: 100%;
    text-align: left;
    color: white;
  }

  p {
    position: relative;
    left: -100%;
    // right: -100%;
    display: inline-block;
    font-size: 15px;
    margin: 0;
    padding: 20px 0 16px;
    font-family: "AktivGrotesk Ex Black Italic";
    animation: marquee 18s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }
  40% {
    left: 38%;
  }
  60% {
    left: 38%;
  }
  100% {
    left: 100%;
  }
}

.main-hero-banner {
  background-color: rgba(209, 209, 209, 0.6);
  max-width: 100% !important;
  margin-top: 0;
}

.hero-banner {
  position: relative;
  max-width: 100% !important;
  padding-bottom: 0;
  min-height: 100vh;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.hero-banner-wrapper {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: calc(100vh - 60px);
}

.hero-banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.shimmer-effect {
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0009, #000 70%) right/300%
    460%;
  mask: linear-gradient(-60deg, #000 30%, #0009, #000 70%) right/300% 460%;
  animation: shimmer 2.5s;
}

.sticky-time-block .shimmer-effect {
  -webkit-mask: unset;
  mask: unset;
  animation: unset;
}

.hero-bg {
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all;
  background-repeat: no-repeat;

  &.active-bg {
    visibility: visible;
    opacity: 1;
    transition: 0.3s all;
  }
}

.hero-banner-content {
  width: 100%;
  padding: 280px 0 120px;
  position: relative;
  z-index: 2;
  border-bottom: 1px solid var(--global--color-gold);

  h1 {
    margin: 0;
    font-size: 64px;
    max-width: 428px;
    text-align: left;
    color: var(--global--color-white);
    font-family: "AktivGrotesk Ex Black Italic";
    letter-spacing: -3.6px;
  }
}

.slider-content {
  display: flex;
  padding: 50px 0;
  overflow-x: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 0;
    background-color: var(--global--color-gold);
    transition: 1.5s all;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    width: 0;
    background-color: var(--global--color-gold);
    transition: 1.5s all;
  }
}

.slider-content.active-line::after,
.slider-content.active-line::before {
  width: 100%;
  transition: 1.5s all;
}

.slide-category-tabs {
  padding-left: 55px;
}

.slide-tabs {
  li {
    display: flex;
    align-items: center;
    list-style-type: none;
    position: relative;
    padding-left: 30px;
    font-family: "AktivGrotesk Ex Black Italic";
    min-height: 40px;
    margin-bottom: 20px;

    &::before {
      content: "";
      height: 13px;
      width: 13px;
      border: 1px solid var(--global--color-white);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    &::after {
      content: "";
      height: 44px;
      width: 1px;
      background-color: var(--global--color-gold);
      position: absolute;
      left: 6px;
      bottom: -32px;
    }

    &.active-slide-tab a {
      font-size: 29px;
      line-height: normal;
      color: var(--global--color-white);
      -webkit-text-stroke: unset;
    }
  }

  a {
    font-size: 13px;
    color: transparent;
    -webkit-text-stroke: 1px var(--global--color-white);
    text-decoration: none;
    transition: unset;

    &:hover {
      color: var(--global--color-white);
      -webkit-text-stroke: unset;
    }
  }
}

.slide-tabs li:last-child:after {
  display: none;
}

.slide-tabs li.active-slide-tab::before {
  background-color: var(--global--color-white);
}

.hero-banner-cta {
  display: none;
  margin: 25px 0 0;
  text-align: center;

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--global--color-white);
    border-color: var(--global--color-white);
    min-width: 232px;
    height: 60px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: "AktivGrotesk Ex Black Italic";

    &:hover {
      background-color: var(--global--color-white);
      color: var(--global--color-focus-tertiary);
    }

    &:first-of-type {
      margin-right: 30px;
    }
  }
}

.slide-tabs-content {
  display: inline-block;
  position: absolute;
  top: 440px;
  right: 0;
  width: 550px;
}

.slide-tab-content {
  visibility: hidden;
  font-size: 16px;
  color: var(--global--color-white);
  opacity: 0;
  z-index: -2;
  transition: 0.6s all;
  height: 0;

  img {
    max-width: 100%;
  }
}

.slide-tab-content.tab-show {
  visibility: visible;
  opacity: 1;
  z-index: 2;
  transition: 0.6s all;
}

.whitebg {
  h1,
  .slide-tabs li.active-slide-tab a {
    -webkit-text-stroke: unset;
    color: var(--global--color-focus-tertiary);
  }

  .slide-tabs li {
    a {
      -webkit-text-stroke: 1px var(--global--color-focus-tertiary);

      &:hover {
        -webkit-text-stroke: unset;
        color: var(--global--color-focus-tertiary);
      }
    }

    &::before {
      border-color: var(--global--color-focus-tertiary);
    }

    &.active-slide-tab::before {
      background-color: var(--global--color-focus-tertiary);
    }
  }

  .wp-block-search__button,
  .wp-block-search__button:focus,
  .wp-block-search .wp-block-search__input:focus,
  .wp-block-search__input {
    color: var(--global--color-focus-tertiary) !important;
    border-color: var(--global--color-focus-tertiary);
  }

  .wp-block-search__button:active,
  .wp-block-search__button:hover,
  .wp-block-search__button:hover:active {
    background-color: var(--global--color-white) !important;
    color: var(--global--color-focus-tertiary) !important;
    border: 1px solid var(--global--color-focus-tertiary);
  }

  .site-promo p,
  .site-promo a {
    color: var(--global--color-focus-tertiaryblack);
  }

  .hero-banner-cta {
    .btn {
      color: var(--global--color-focus-tertiary);
      border-color: var(--global--color-focus-tertiary);

      &:hover {
        color: var(--global--color-white);
        background-color: var(--global--color-focus-tertiary);
      }
    }
  }
}

.home.black-color {
  .hero-banner-cta a {
    color: #000;
    border-color: #000;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  .site-promo,
  .hero-banner-content {
    border-color: var(--global--color-gold);
  }

  .slider-content::before,
  .slider-content::after,
  .slide-tabs li::after {
    background-color: var(--global--color-gold);
  }
}

.hero-banner-cta.active-btn {
  display: block;
}

.wp-block-contact-form-7-contact-form-selector input {
  width: 100%;
}

.time-location-wrapper {
  display: flex;
  justify-content: space-between;
  height: 58px;
}

.sticky-time-location {
  position: fixed;
  top: 78px;
  left: 0;
  right: 0;
  background-color: #000;
  &::after {
    content: "";
    position: absolute;
    left: 40px;
    right: 40px;
    top: 0px;
    height: 1px;
    background-color: var(--global--color-white);
  }
  @media only screen and (max-width: 499px) {
    top: 58px;
  }
}

.prod-slider .prod-slide-item img {
  max-width: 100%;
}

.prod-slider {
  width: 100%;
  max-width: 710px;
  text-align: center;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  display: inline-flex;
  align-items: center;
}

.prod-slide-item {
  margin: auto;
  width: 100%;
  position: absolute;
  cursor: pointer;
  width: max-content;
  z-index: 3;
  transition: transform 1s;
}

.prod-slide-item.activeSlide {
  z-index: -1;
  pointer-events: none;
}

.prod-slide-item img {
  max-width: 100%;
  cursor: pointer;
  height: 210px;
  width: auto;
}

.prod-slide-item.activeSlide img {
  transform: scale(3);
  width: auto;
  transition: 1.2s all;
  pointer-events: none;
}

@media only screen and (min-width: 1921px) {
  .slide-tabs-content {
    top: 510px;
  }

  .hero-banner-content {
    padding: 360px 0 200px;
  }

  .hero-banner-content {
    max-width: 1850px;
    width: 100%;
  }

  .hero-banner-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1850px) {
  .hero-banner-content h1 {
    font-size: 55px;
    max-width: 365px;
  }

  .slide-category-tabs {
    padding-left: 25px;
  }

  .slide-tabs li {
    min-height: 35px;
    margin-bottom: 15px;

    &.active-slide-tab a {
      font-size: 22px;
    }

    &::after {
      height: 36px;
      bottom: -26px;
    }
  }

  .hero-banner-cta {
    padding-right: 160px;
  }
}

@media only screen and (max-width: 1650px) {
  .slide-tabs-content {
    width: 470px;
    top: 430px;
  }

  .prod-slide-item img {
    height: 190px;
  }
}

@media only screen and (max-width: 1500px) {
  .hero-banner-content h1 {
    font-size: 44px;
    max-width: 290px;
  }

  .slide-tabs-content {
    max-width: 685px;
    width: 480px;
    top: 400px;
  }

  .slide-tab-content img {
    max-height: 650px;
  }

  .slide-category-tabs {
    padding-left: 15px;
  }

  .slide-tabs li {
    padding-left: 25px;
    min-height: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &::after {
      height: 30px;
      bottom: -22px;
    }

    &.active-slide-tab a {
      font-size: 17px;
    }
  }

  .hero-banner-cta {
    padding-right: 160px;

    .btn {
      min-width: 200px;
      height: 50px;
      padding: 14px 20px;

      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1360px) {
  .slide-tabs-content {
    width: 260px;
    top: 350px;
    right: 100px;
  }

  .hero-banner-content {
    padding: 220px 0 55px;
  }

  .slide-tab-content img {
    height: 140px;
  }

  .hero-banner::after {
    min-width: 380px;
  }
}

@media only screen and (max-width: 1199px) {
  .site-promo {
    p {
      animation: marquee 12s linear infinite;
    }
  }

  .hero-banner-wrapper {
    padding: 0 25px;
  }

  .hero-banner-content {
    padding: 200px 0 50px;

    h1 {
      font-size: 36px;
      max-width: 230px;
    }
  }

  .slide-tabs-content {
    top: 300px;
    max-width: 500px;
  }

  .slider-content {
    padding: 30px 0;
  }

  .slide-tabs li.active-slide-tab a {
    font-size: 13px;
  }

  .slide-tab-content img {
    max-height: 470px;
  }

  .hero-banner-cta {
    padding-right: 170px;

    .btn {
      font-size: 20px;
      min-width: 175px;

      &:first-of-type {
        margin-right: 15px;
      }
    }
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    40% {
      left: 32%;
    }
    60% {
      left: 32%;
    }
    100% {
      left: 100%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .slide-tabs-content {
    max-width: 235px;
  }

  .slide-tab-content img {
    height: 130px;
  }
}

@media only screen and (max-width: 991px) {
  .site-promo {
    p {
      animation: marquee 8s linear infinite;
    }
  }

  .hero-banner-content {
    padding: 145px 0 50px;

    h1 {
      font-size: 36px;
      max-width: 360px;
      width: auto;
      letter-spacing: -1px;
    }
  }

  .slide-tabs-content {
    position: relative;
    top: unset;
    right: unset;
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }

  .prod-slider {
    max-width: 200px;
    height: 500px;
  }

  .slide-tab-content {
    display: none;

    img {
      height: 150px;
    }

    &.tab-show {
      height: 100%;
      display: block;
    }
  }

  .slide-category-tabs {
    padding-left: 25px;
    min-width: 290px;
  }

  .slide-tabs li.active-slide-tab a {
    font-size: 18px;
  }

  .hero-banner-cta {
    padding-right: 0;
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    40% {
      left: 28%;
    }
    60% {
      left: 28%;
    }
    100% {
      left: 100%;
    }
  }

  .hero-bg {
    background-position: left;
  }
}

@media only screen and (max-width: 767px) {
  .hero-bg {
    background-position: 75%;
  }

  .hero-banner-content {
    padding: 150px 0 45px;

    h1 {
      font-size: 32px;
    }
  }

  .slider-content {
    padding: 0 0 10px;
  }

  .slide-tabs-content {
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      top: 24%;
      left: 0;
      height: 1px;
      width: 0;
      background-color: var(--global--color-gold);
      transition: 1.5s all;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 18%;
      right: 0;
      height: 1px;
      width: 0;
      background-color: var(--global--color-gold);
      transition: 1.5s all;
      z-index: -1;
    }
  }

  .slider-content::after,
  .slider-content::before {
    display: none;
  }

  .slide-category-tabs {
    padding-left: 20px;
    min-width: 232px;
  }

  .slide-tabs li {
    margin-bottom: 8px;

    &::after {
      height: 22px;
      bottom: -15px;
    }

    &.active-slide-tab a {
      font-size: 15px;
    }
  }

  .slide-tabs-content.active-line::after,
  .slide-tabs-content.active-line::before {
    width: 100%;
    transition: 1.5s all;
  }

  .hero-banner-cta {
    .btn {
      font-size: 16px;
      min-width: 155px;
      height: 44px;
      padding: 10px 20px;
    }
  }

  .hero-banner::after {
    min-width: 320px;
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    40% {
      left: 8%;
    }
    60% {
      left: 8%;
    }
    100% {
      left: 100%;
    }
  }

  .prod-slider {
    max-width: 170px;
    height: 400px;
  }

  .slide-tab-content img {
    height: 120px;
  }

  .hero-banner-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 499px) {
  .site-promo p {
    font-size: 12px;
    padding: 8px 0;
    animation: marquee 6s linear infinite;
  }

  .hero-banner::before {
    height: 42px;
  }

  .hero-banner-content {
    padding: 115px 0 30px;
  }

  html,
  .slide-tabs-content {
    overflow-x: hidden;
  }

  .hero-banner-content h1 {
    font-size: 24px;
    max-width: 100%;
  }

  .hero-banner-cta .btn {
    min-width: 120px;
  }

  .hero-banner::after {
    min-width: 170px;
  }

  .hero-banner::before {
    width: 10.5vw;
  }

  .hero-banner-cta {
    margin-top: 12px;

    .btn {
      height: 38px;
    }
  }

  .slide-tabs li {
    padding-left: 20px;
    min-height: 24px;

    &::after {
      height: 20px;
      bottom: -14px;
    }
  }

  .slide-tabs a,
  .slide-tabs li.active-slide-tab a {
    font-size: 10px;
  }

  .prod-slider {
    max-width: 120px;
    height: 255px;
  }

  .slide-tab-content img {
    height: 86px;
  }
}

@media only screen and (max-width: 374px) {
  .site-promo p {
    font-size: 10px;
  }

  .slider-content {
    display: block;
  }

  .hero-banner-cta {
    margin-top: 0;
  }

  .hero-banner-cta .btn {
    font-size: 13px;
    padding: 7px 10px;
  }

  .slide-category-tabs {
    padding-left: 0;
    margin-top: 20px;
  }

  .slide-tab-content img {
    max-height: 250px;
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    40% {
      left: 2%;
    }
    60% {
      left: 2%;
    }
    100% {
      left: 100%;
    }
  }

  .prod-slider {
    max-width: 100px;
    height: 255px;
  }

  .slide-tab-content img {
    height: 72px;
  }
}
