.wp-block-quote {
  border-left: none;

  &:before {
    content: "\201C";
    font-size: var(--quote--font-size);
    line-height: var(--quote--line-height);
    left: 8px;
  }

  .wp-block-quote__citation,
  cite,
  footer {
    .has-background &,
    [class*="background-color"] &,
    [style*="background-color"] &,
    .wp-block-cover[style*="background-image"] & {
      color: currentColor;
    }
  }

  /**
	 * Block Options
	 */
  &.has-text-align-right {
    margin: var(--global--spacing-vertical) var(--global--spacing-horizontal)
      var(--global--spacing-vertical) auto;
    padding-right: 0;
    border-right: none;

    // Hide the left aligned quote.
    &:before {
      display: none;
    }

    // Align the quote left of the text.
    p:before {
      content: "\201D";
      font-size: var(--quote--font-size);
      font-weight: normal;
      line-height: var(--quote--line-height);
      margin-right: 5px;
    }
  }

  &.has-text-align-center {
    margin: var(--global--spacing-vertical) auto;

    &:before {
      display: none;
    }
  }

  &.is-large,
  &.is-style-large {
    padding-left: 0;
    padding-right: 0;

    /* Resetting margins to match _block-container.scss */
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);

    p {
      font-size: var(--quote--font-size-large);
      font-style: var(--quote--font-style-large);
      line-height: var(--quote--line-height-large);
    }

    &:before {
      font-size: var(--quote--font-size-large);
      line-height: var(--quote--line-height-large);
      left: calc(-1 * var(--global--spacing-horizontal));
    }

    &.has-text-align-right {
      // Hide the left aligned quote.
      &:before {
        display: none;
      }

      // Align the quote left of the text.
      p:before {
        content: "\201D";
        font-size: var(--quote--font-size-large);
        font-weight: normal;
        line-height: var(--quote--line-height-large);
        margin-right: 10px;
      }
    }

    .wp-block-quote__citation,
    cite,
    footer {
      color: var(--global--color-primary);
      font-size: var(--global--font-size-sm);
    }

    @include media(mobile-only) {
      padding-left: var(--global--spacing-horizontal);

      &:before {
        left: 0;
      }

      &.has-text-align-right {
        padding-left: 0;
        padding-right: var(--global--spacing-horizontal);

        &:before {
          right: 0;
        }
      }

      &.has-text-align-center {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @include media(mobile-only) {
    &.has-text-align-right {
      padding-left: 0;
      padding-right: calc(0.5 * var(--global--spacing-horizontal));

      &:before {
        right: 0;
      }
    }

    &.has-text-align-center {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
