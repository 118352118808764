ul,
ol {
  font-family: var(--list--font-family);
  margin: 0;
  padding-left: calc(2 * var(--global--spacing-horizontal));

  // Utility classes
  &.aligncenter {
    list-style-position: inside;
    padding: 0;
  }

  &.alignright {
    list-style-position: inside;
    text-align: right;
    padding: 0;
  }
}

ul {
  list-style-type: disc;

  ul {
    list-style-type: circle;
  }
}

ol {
  list-style-type: decimal;

  ul {
    list-style-type: circle;
  }
}

dt {
  font-family: var(--definition-term--font-family);
  font-weight: bold;
}

dd {
  margin: 0;
  padding-left: calc(2 * var(--global--spacing-horizontal));
}
