.sticky {
  // This class is required to pass ThemeCheck.
}

.no-results.not-found > *:first-child {
  margin-bottom: calc(3 * var(--global--spacing-vertical));
}

// Styling for wp_link_pages.
.page-links {
  clear: both;

  .post-page-numbers {
    display: inline-block;
    margin-left: calc(0.66 * var(--global--spacing-unit));
    margin-right: calc(0.66 * var(--global--spacing-unit));
    min-width: 44px;
    min-height: 44px;

    &:first-child {
      margin-left: 0;
    }
  }
}
.container {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 1440px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.title {
  font-family: "AktivGrotesk Ex Black Italic";
  text-align: center;
  text-transform: uppercase;
  color: var(--global--color-focus-tertiary);

  @media screen and (max-width: 767px) {
    font-size: calc(1.65 * var(--global--font-size-lg));
  }
  @media screen and (max-width: 412px) {
    font-size: var(--global--font-size-lg);
  }
}
