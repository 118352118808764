.is-IE {
  &.is-dark-theme {
    color: #fff;

    *,
    a,
    .site-description,
    .entry-title,
    .entry-footer,
    .widget-area,
    .post-navigation .meta-nav,
    .footer-navigation-wrapper li a:link,
    .site-footer > .site-info,
    .site-footer > .site-info a,
    .site-footer > .site-info a:visited {
      color: #fff;
    }

    .skip-link:focus {
      color: #21759b;
    }
  }

  .navigation .nav-links {
    display: block;
  }

  .post-thumbnail .wp-post-image {
    min-width: auto;
  }
}
