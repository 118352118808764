.events-page {
  .entry-header {
    p {
      font-size: 16px;
      text-transform: initial;
    }
  }

  .rockstar-related-products {
    margin-bottom: 50px;
  }
}

.mapsvg-wrap-all {
  max-width: 973px;
  margin: 60px auto 80px;
}

.mapsvg-marker-active {
  background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/active-map.svg");
  background-repeat: no-repeat;
  height: 42px;
  width: 42px;
  background-size: contain;

  img {
    display: none;
  }
}

.event-filter-list {
  text-align: center;

  ul {
    display: inline-flex;
    max-width: unset;
    width: auto;
    position: relative;
    margin: 0 auto 56px;
    justify-content: space-between;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: #606060;
      z-index: 0;
    }
  }

  li {
    list-style-type: none;
    border-bottom: 3px solid #606060;
    z-index: 2;
  }

  ul a {
    display: block;
    font-size: 15.1304px;
    line-height: 34px;
    color: $med-gray;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: bold;
    padding: 0 30px;
    text-align: center;
  }

  .active-event-tab {
    border-color: var(--global--color-gold);
    border-bottom: 4px solid var(--global--color-focus-tertiary);

    a {
      color: var(--global--color-focus-tertiary);
    }
  }
}

.events-list-block {
  margin-bottom: 90px;

  .event-content {
    display: none;

    &.active-events {
      display: block;
    }
  }

  .no-events {
    font-size: 16px;
  }

  .event-post-item {
    display: flex;
    padding: 30px 0;
    border-top: 1px solid #606060;

    &:last-of-type {
      border-bottom: 1px solid #606060;
    }
  }

  .event-post-img {
    flex: 0 0 37%;
    max-width: 37%;

    a {
      padding: 0;
    }

    .event-img {
      height: 330px;
      width: 100%;
      background-size: cover;
      background-position: center center;
    }
  }

  .event-post-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 63%;
    max-width: 63%;
    padding-left: 50px;
    text-align: left;

    .event-header-info {
      position: relative;
      margin-bottom: 30px;

      span {
        font-size: 13px;
        line-height: 26px;
        letter-spacing: 6px;
        margin-bottom: 8px;
        display: inline-block;
        text-transform: uppercase;
      }
    }

    h3 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 32px;
      line-height: 30px;
      text-align: left;
      padding: 0;
      margin-bottom: 20px;
      letter-spacing: -1.91px;
      color: var(--global--color-focus-tertiary);
      text-transform: uppercase;
    }

    .date-container {
      display: flex;

      span {
        display: inline-block;
        padding: 0 15px;
        font-size: inherit;
      }
    }

    .event-date {
      font-size: 31px;
      line-height: 30px;
      color: var(--global--color-focus-tertiary);
      text-transform: uppercase;
      white-space: nowrap;
    }

    .event-exc {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      max-width: 560px;
      letter-spacing: 0.47px;

      .feature-btn {
        margin-top: 40px;
        font-size: 20px;
        padding: 15px 50px;
        &.focus-text {
          font-size: 13px;
        }
      }
    }

    .event-btn {
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .events-list-block {
    .event-post-details {
      .event-date {
        font-size: 26px;
      }
    }

    .event-post-details {
      padding-left: 40px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .mapsvg-marker-active {
    height: 36px;
    width: 36px;

    img {
      display: none;
    }
  }

  .mapsvg-marker img {
    height: 36px;
    width: 36px;
  }

  .mapsvg-wrap-all {
    margin: 50px auto;
  }

  .events-list-block {
    .event-post-item {
      display: block;
    }

    .event-post-img {
      max-width: 100%;
    }

    .event-post-details {
      max-width: 100%;
      padding-left: 0;
      margin-top: 20px;
    }

    .event-post-info {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .mapsvg-wrap-all {
    margin: 30px auto;
  }

  .event-filter-list {
    ul {
      margin: 0 auto 40px;

      a {
        font-size: 13px;
      }
    }
  }

  .events-page {
    .related-product-title {
      margin-bottom: 20px;
    }
  }

  .events-list-block {
    .event-post-img .event-img {
      height: 260px;
    }

    .event-post-details {
      margin-top: 30px;
      position: relative;

      .event-post-info {
        margin-bottom: 0;
      }

      .event-header-info {
        margin-bottom: 20px;

        span {
          font-size: 10px;
          line-height: normal;
        }

        h3 {
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 15px;

          p {
            line-height: 26px;
          }
        }

        .event-date {
          font-size: 14px;
          margin-bottom: 4px;
        }
      }

      .event-exc {
        font-size: 16px;
        font-weight: 400;
        text-align: center;

        p {
          max-width: 89%;
          text-align: left;
        }

        .feature-btn {
          width: 100%;
          text-align: center;
          margin: 20px auto 0;
          max-width: 425px;
          padding: 15px 30px;
        }
      }

      .event-btn {
        font-size: 11px;
        padding: 3px 28px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .mapsvg-scrollpane {
    svg > g > path {
      stroke-width: 4px !important;
    }

    svg > g > path:first-of-type {
      stroke-width: 2px !important;
    }
  }
}

@media only screen and (max-width: 425px) {
  .events-page .entry-header p {
    font-size: 14px;
  }

  .events-list-block .event-post-details .event-btn {
    position: relative;
    right: unset;
    bottom: unset;
    margin-top: 20px;
  }

  .events-list-block .event-post-details .event-exc {
    max-width: 100%;
    padding-right: 0;
  }

  .events-list-block .event-post-details .event-header-info .event-date {
    position: relative;
  }

  .events-list-block .event-post-details .event-header-info h3 {
    padding-right: 0;
    display: block;

    span {
      padding: 0 5px 0 10px;
      font-size: 18px;
    }
  }
}
