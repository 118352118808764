.widget-area {
  @extend %responsive-alignwide-width;
  margin-top: calc(6 * var(--global--spacing-vertical));
  padding-bottom: calc(var(--global--spacing-vertical) / 3);
  color: var(--footer--color-text);
  font-size: var(--footer--font-size);
  font-family: var(--footer--font-family);

  @include media(laptop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: calc(2 * var(--global--spacing-horizontal));
  }

  @include media(wide) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media(mobile-only) {
    margin-top: calc(3 * var(--global--spacing-vertical));
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      line-height: var(--widget--line-height-list);
    }

    &.children {
      margin-left: var(--widget--spacing-menu);
    }
  }

  a {
    color: var(--footer--color-link);
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: currentColor;

    &:link,
    &:visited,
    &:active {
      color: var(--footer--color-link);
    }

    &:hover {
      color: var(--footer--color-link-hover);
      text-decoration-style: dotted;
    }
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.widget {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: var(--widget--font-weight-title);
    line-height: var(--widget--line-height-title);
  }

  h1 {
    font-size: var(--global--font-size-md);
  }

  h2 {
    font-size: var(--global--font-size-sm);
  }

  h3 {
    font-size: var(--global--font-size-xs);
  }

  h4 {
    font-size: var(--global--font-size-xs);
  }

  h5 {
    font-size: var(--global--font-size-xs);
  }

  h6 {
    font-size: var(--global--font-size-xs);
  }
}

.widget_rss a.rsswidget .rss-widget-icon {
  display: none;
}
