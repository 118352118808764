/* Next/Previous navigation */

// All navigation
.navigation {
  color: var(--global--color-primary);

  a {
    color: var(--global--color-primary);
    text-decoration: none;

    &:hover {
      color: var(--global--color-primary-hover);
      text-decoration: underline;
      text-decoration-style: dotted;
    }

    &:focus {
      color: var(--global--color-secondary);
    }

    &:active {
      color: var(--global--color-primary);
    }
  }

  .nav-links {
    > * {
      min-width: 44px;
      min-height: 44px;
    }

    .nav-next a,
    .nav-previous a {
      display: flex;
      flex-direction: column;
    }

    .dots {
      text-align: center;
    }

    @include media(tablet) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .nav-next,
      .nav-previous {
        flex: 0 1 auto;
        margin-bottom: inherit;
        margin-top: inherit;
        max-width: calc(50% - (0.5 * var(--global--spacing-unit)));
      }

      .nav-next {
        text-align: right;
      }
    }
  }

  .svg-icon {
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
    position: relative;
  }

  .nav-previous .svg-icon,
  .prev .svg-icon {
    top: -2px;
    margin-right: calc(0.25 * var(--global--spacing-unit));
  }

  .nav-next .svg-icon,
  .next .svg-icon {
    top: -1px;
    margin-left: calc(0.25 * var(--global--spacing-unit));
  }
}

// Singular navigation
.post-navigation {
  margin: var(--global--spacing-vertical) auto;

  @include media(desktop) {
    margin: var(--global--spacing-vertical) auto;
  }

  @extend %responsive-alignwide-width;

  .meta-nav {
    line-height: var(--global--line-height-body);
    color: var(--global--color-primary);
  }

  .post-title {
    display: inline-block;
    font-family: var(--global--font-primary);
    font-size: var(--global--font-size-lg);
    font-weight: var(--pagination--font-weight-strong);
    line-height: var(--global--line-height-heading);
    @include media(desktop) {
      margin: 5px calc(24px + (0.25 * var(--global--spacing-unit))) 0;
    }
  }

  .nav-links {
    @include media(mobile) {
      justify-content: space-between;
    }
  }

  .nav-next,
  .nav-previous {
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Index/archive navigation
.pagination,
.comments-pagination {
  border-top: 3px solid var(--global--color-border);
  padding-top: var(--global--spacing-vertical);
  margin: var(--global--spacing-vertical) auto;

  @include media(desktop) {
    margin: var(--global--spacing-vertical) auto;
  }

  @extend %responsive-alignwide-width;

  // Resets the top margin added to the .nav-links items below.
  .nav-links {
    margin-top: calc(-1 * var(--global--spacing-vertical));

    a:hover {
      color: var(--pagination--color-link-hover);
    }

    .is-dark-theme & {
      a:active,
      a:hover:active,
      a:hover:focus {
        color: var(--global--color-background);
      }
    }

    .has-background-white & {
      a:active,
      a:hover:active,
      a:hover:focus {
        color: var(--global--color-white);
      }
    }
  }

  .nav-links > * {
    color: var(--pagination--color-text);
    font-family: var(--pagination--font-family);
    font-size: var(--pagination--font-size);
    font-weight: var(--pagination--font-weight);
    margin-top: var(--global--spacing-vertical);
    margin-left: calc(0.66 * var(--global--spacing-unit));
    margin-right: calc(0.66 * var(--global--spacing-unit));

    &.current {
      text-decoration: underline;
    }

    &:not(.dots):not(.current):hover {
      text-decoration-style: dotted;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.next {
      margin-left: auto;
    }

    &.prev {
      margin-right: auto;
    }
  }

  @include media(desktop-only) {
    .nav-links {
      display: flex;
      flex-wrap: wrap;
    }

    .page-numbers {
      &.prev,
      &.next {
        flex: 0 1 auto;
      }
    }
  }

  @include media(mobile-only) {
    .nav-short {
      display: none;
    }
  }
}

// Comments pagination
.comments-pagination {
  padding-top: calc(0.66 * var(--global--spacing-vertical));
  margin: calc(3 * var(--global--spacing-vertical)) auto;

  @include media(desktop) {
    margin: calc(3 * var(--global--spacing-vertical)) auto
      calc(4 * var(--global--spacing-vertical)) auto;
  }

  .nav-links > * {
    font-size: var(--global--font-size-md);
  }
}
