.wp-block-image {
  text-align: center;
  a:focus img {
    outline-offset: 2px;
  }
}

// Remove vertical margins from image block wrappers when floated
.entry-content > *[class="wp-block-image"] {
  margin-top: 0;
  margin-bottom: 0;

  // Remove top margins from the following element when previous image block is floated
  + * {
    margin-top: 0;
  }
}
