/* Over here, place any elements that do not need to have their own file. */
b,
strong {
  font-weight: 700;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

pre {
  white-space: pre;
  overflow-x: auto;
}

#consent-banner {
  border-top: 1px solid white;

  button {
    background-color: #cf9c43 !important;
    color: var(--global--color-background);
    border: none;
    span {
      font-family: "AktivGrotesk Ex Black Italic" !important;
      text-transform: uppercase !important;
    }
  }
}
