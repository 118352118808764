.category-music,
.category-gaming,
.category-sport,
.page-template-culture-page {
  .rockstar-related-products {
    margin-bottom: 50px;
  }
}

.category-custom-contents {
  .filter-custom-content {
    display: block;
  }
}

.culture-banner {

  h1 {
    color: transparent;
    font-family: "AktivGrotesk Ex Black Italic";
    font-size: 80px;
    line-height: 1;
    text-transform: uppercase;
    -webkit-text-stroke: 1px var(--global--color-white);
    background-image: linear-gradient(
      var(--global--color-white),
      var(--global--color-white)
    );
    -webkit-background-clip: text;
    background-clip: text;
    letter-spacing: -1.2px;
    background-size: 100% 0;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .culture-banner-inner {
    min-height: 532px;
    max-width: 1620px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 1700px){
      margin: 0 40px;
    }
    @media screen and (max-width: 1200px){
      margin: 0 25px;
    }
    @media screen and (max-width: 767px){
      margin: 0;
    }
  }
}

.culture-columns {
  display: flex;
  padding: 60px 0 0 0;
  margin: 0 -15px;
  flex-wrap: wrap;

  .culture-small-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 50px;
  }

  .culture-title {
    h2 {
      font-size: 30px;
      line-height: 1;
      padding-bottom: 15px;
      margin-bottom: 25px;
      text-transform: uppercase;
      border-bottom: 1px dashed var(--global--color-white);
    }
  }

  .culture-img,
  .culture-img-inner {
    min-height: 330px;
    background-repeat: no-repeat;
    background-color: gray;
    background-size: cover;
    background-position: center center;
    transition: 0.3s all;
    overflow: hidden;
  }

  .culture-img:hover {
    .culture-img-inner {
      transform: scale(1.1);
    }
  }

  .culture-details {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    p {
      font-size: 13px;
      line-height: 16px;
      font-weight: 700;
      font-weight: bold;
      letter-spacing: 0.4px;
      max-width: 45%;
      margin: 0 15px 0 0;
    }

    .culture-btn-wrapper {
      @media screen and (max-width: 991px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 992px) {
        margin-left: 16px;
      }
    }

    .culture-btn {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.featured-athletes-title,
.featured-live-title,
.featured-events-title,
.featured-artists-title {
  .culture-title {
    text-align: center;
    line-height: 1;
    color: var(--global--color-focus-tertiary);
    margin-bottom: 50px;
    letter-spacing: -3.5px;
    text-transform: uppercase;
  }
}

.featured-youtube {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  max-height: 787px;
  min-height: 700px;

  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    min-height: 700px;
  }
}

.category-feature-sport,
.category-feature-music {
  .featured-athletes,
  .filter-custom-contents,
  .culture-banner {
    margin-bottom: 0;
  }

  .featured-athletes {
    margin-top: 40px;
  }

  .events-list-block {
    margin-top: 50px;
  }
}

.category-feature-gaming {
  .culture-banner {
    margin-bottom: 0;
  }

  .featured-live-stream {
    margin-top: 40px;
  }
}

.archive .related-products {
  margin: 30px 0 50px;
}

.featured-artists {
  margin: 40px 0 60px;
}

.featured-artists-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  .featured-artist {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 30px;
    line-height: 32px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px dashed var(--global--color-gold);

    a {
      color: var(--global--color-gold);
      text-transform: uppercase;
    }
  }

  .name-tag {
    font-size: 18px;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
  }

  .featured-artist-exc {
    max-width: 330px;
  }

  .featured-artist-thumb {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;

    .featured-artist-img {
      width: 100%;
      height: 330px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .featured-athletes-title,
  .featured-live-title,
  .featured-events-title,
  .featured-artists-title {
    .culture-title {
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 50px;
    }
  }

  .culture-columns .culture-details p {
    max-width: 65%;
  }

  .featured-athletes {
    margin: 50px 0;
  }

  .culture-banner {
    margin: 50px 0 20px;
  }

  .featured-live-stream {
    margin-top: 20px;
  }

  .archive {
    .filter-custom-contents,
    .featured-live-stream {
      margin-bottom: 50px;
    }
  }

  .featured-artists-list {
    flex-wrap: wrap;

    .featured-artist {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .culture-columns {
    padding: 40px 0 0 0;

    .culture-title h2 {
      font-size: 26px;
    }

    .culture-details {
      display: block;

      p {
        max-width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }

  .featured-athletes-title,
  .featured-live-title,
  .featured-events-title,
  .featured-artists-title {
    .culture-title {
      margin-bottom: 35px;
    }
  }

  .category-feature-music .events-list-block {
    margin: 20px 0 50px;
  }

  .featured-artists-list h3 {
    font-size: 26px;
    line-height: 32px;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }

  .featured-artists-list .name-tag {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .culture-banner h1 {
    font-size: 56px;
  }

  .culture-banner {
    .culture-banner-inner {
      min-height: 490px;
    }
  }

  .culture-columns {
    .culture-img,
    .culture-img-inner {
      min-height: 180px;
    }

    .culture-title h2 {
      font-size: 24px;
    }
  }

  .featured-athletes-title,
  .featured-live-title,
  .featured-events-title,
  .featured-artists-title {
    .culture-title {
      font-size: 40px;
      line-height: 40px;
    }
  }

  .category-feature-sport {
    .filter-custom-content:last-of-type {
      margin-bottom: 15px;
    }
  }

  .category-feature-music {
    .events-list-block .event-post-details .event-header-info,
    .events-list-block .event-post-details .event-header-info h3 {
      margin-bottom: 10px;
    }
  }

  .featured-artists {
    margin: 10px 0 30px;
  }

  .category-feature-sport .featured-athletes,
  .category-feature-gaming .featured-live-stream {
    margin-top: 10px;
  }

  .featured-artists-list .featured-artist {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 574px) {
  .culture-banner h1 {
    font-size: 45px;
  }

  .culture-columns {
    padding-top: 40px;

    .culture-small-item {
      display: flex;
      flex-direction: column;

      > a {
        order: 1;
      }

      .culture-title {
        order: 2;
        margin-top: 30px;

        h2 {
          margin-bottom: 15px;
        }
      }

      .culture-details {
        order: 3;
        margin-top: 0;
      }
    }
  }

  .featured-artists-list {
    h3 {
      font-size: 23px;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    .featured-artist-thumb {
      margin-bottom: 10px;

      .featured-artist-img {
        height: 252px;
      }
    }

    .name-tag {
      font-size: 14px;
    }

    .featured-artist-exc {
      font-size: 10px;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 767px) {
  .culture-banner {
    padding-top: 25px;
  }

  .culture-columns .culture-small-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .featured-athletes-title .culture-title,
  .featured-live-title .culture-title,
  .featured-events-title .culture-title {
    font-size: 32px;
    line-height: 34px;
  }

  .featured-athletes-title .culture-title,
  .featured-live-title .culture-title,
  .featured-events-title .culture-title {
    margin-bottom: 25px;
  }

  .featured-youtube {
    min-height: 550px;
  }
}
