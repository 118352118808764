.brand-ambassador-sub-title {
  font-size: 16px;
  line-height: 27px;
  color: #fff;
  font-family: "AktivGrotesk Std Bold";
  max-width: 992px !important;
  margin-bottom: 55px;
}

.brand-ambassador-img {
  img {
    width: 100%;
    min-height: 530px;
    object-fit: cover;
    object-position: center;
  }
}

.requirement-perks-block {
  margin: 60px 0;

  h3 {
    border-bottom: 1.04438px dashed #ffffff;
    padding-bottom: 25px;
  }

  ul {
    li {
      position: relative;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      list-style-type: none;
      margin-bottom: 30px;
      padding-left: 20px;

      &::after {
        content: "-";
        font-size: 16px;
        position: absolute;
        left: 0;
        display: block;
        top: 0;
      }
    }
  }
}

.apply-today-form {
  padding: 50px 0 80px;
  max-width: 100% !important;
  background: linear-gradient(-90deg, #1e1e20 5.87%, #000000 100%);

  .wp-block-column h2 {
    margin-bottom: 70px;
    color: #fff;
  }

  form {
    .field-group {
      display: flex;
      margin-bottom: 11px;

      &.half {
        margin-left: -6px;
        margin-right: -6px;

        & > div {
          width: 50%;
          padding: 0 6px;
        }
      }

      > .field > span {
        position: relative;
        display: block;
      }

      .wpcf7-form-control-wrap {
        width: 100%;
      }

      input {
        font-family: "AktivGrotesk Ex Black Italic";
        width: 100%;
        background: transparent;
        border: 1px solid #ffffff;
        height: 58px;
        font-size: 18px;
        letter-spacing: -1.08631px;
        color: rgba(255, 255, 255, 0.3);
        text-transform: uppercase;

        &:not(:only-child) {
          border-color: #ff0000;
        }

        @media screen and (max-width: 991px) {
          font-size: 13.8124px;
          line-height: 15px;
          letter-spacing: -0.828744px;
        }

        &:focus {
          outline: none;
        }
      }

      textarea {
        font-family: "AktivGrotesk Ex Black Italic";
        width: 100%;
        background: transparent;
        border: 1px solid #ffffff;
        font-size: 18px;
        letter-spacing: -1.08631px;
        color: rgba(255, 255, 255, 0.3);
        text-transform: uppercase;

        &:not(:only-child) {
          border-color: #ff0000;
        }

        @media screen and (max-width: 991px) {
          font-size: 13.8124px;
          line-height: 15px;
          letter-spacing: -0.828744px;
        }

        &:focus {
          outline: none;
        }
      }

      input + span {
        font-size: 6.63363px;
        line-height: 13px;
        letter-spacing: 1.59207px;
        color: #ff0000;
        text-transform: uppercase;
        position: absolute;
        top: 5px;
        right: 20px;
      }
    }
  }

  .field-bottom {
    margin-top: 19px;
    margin-bottom: 30px;

    span {
      margin: 0;
      display: block;

      &.wpcf7-list-item {
        margin-bottom: 20px;
      }

      @media screen and (max-width: 360px) {
        &.wpcf7-list-item {
          width: 100%;
        }
      }

      &.checkbox-wrap {
        width: 100%;
        align-items: center;

        @media screen and (max-width: 767px) {
          justify-content: space-between;
          padding-right: 90px;
        }

        @media screen and (max-width: 500px) {
          padding-right: 20px;
        }

        & > span {
          width: 100%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        @media screen and (max-width: 360px) {
          flex-direction: column;

          & > span {
            margin-bottom: 10px;
          }
        }
      }
    }

    input[type="checkbox"] {
      border: 1px solid #fff;
      background: transparent;
      width: 25px;
      height: 25px;
      min-width: 25px;
      vertical-align: middle;
      margin-right: 20px;

      &:focus {
        outline: none;
      }

      &:after {
        left: 7px;
        top: 4px;
        border-color: #ffffff;
      }
    }

    label {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.47721px;
      text-transform: uppercase;
      color: #fff;
      display: flex;
      align-items: center;
      margin: 0;
    }

    > label {
      font-size: 20px;
      line-height: 22px;
      font-family: "AktivGrotesk Ex Black Italic";
      letter-spacing: -1.08631px;
      color: rgba(255, 255, 255, 0.3);
      margin-bottom: 12px;
    }
  }

  form > p {
    text-align: center;
    margin-top: 40px;
  }

  .wpcf7-spinner {
    display: none;
  }

  input[type="submit"] {
    width: 100%;
    height: 58px;
    background: #cf9c43 !important;
    color: #000 !important;
    padding: 10px;
    font-family: "AktivGrotesk Ex Black Italic";
    font-size: 18px;
    text-transform: uppercase;
    max-width: 657px;

    &:hover {
      border-color: #cf9c43;
      color: #cf9c43 !important;
      background-color: transparent !important;
      outline: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  .wpcf7 {
    margin: 0;
  }

  .widget_block:last-child {
    p {
      margin: 0 !important;
    }
  }

  .form-col-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 -45px;

    @media screen and (max-width: 1199px) {
      margin: 0 -15px;
    }

    .form-col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 45px;

      @media screen and (max-width: 1199px) {
        padding: 0 15px;
      }
    }
  }
}

.mb-8 {
  margin-bottom: 80px !important;
}

@media screen and (max-width: 991px) {
  .requirement-perks-block {
    margin: 30px 0 15px;

    h3 {
      font-size: 28px;
      padding-bottom: 20px;
    }
  }

  .brand-ambassador-img img {
    min-height: 450px;
  }

  .apply-today-form {
    .wp-block-column h2 {
      margin-bottom: 40px;
    }

    .form-col-wrapper {
      display: block;
      margin: 0;

      .form-col {
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .brand-ambassador-sub-title {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .brand-ambassador-img img {
    height: 486px;
  }

  .requirement-perks-block {
    display: block;

    h3 {
      font-size: 24px;
      padding-bottom: 15px;
    }

    .wp-block-column {
      margin: 0;
    }

    ul {
      margin-bottom: 40px !important;

      li {
        margin-bottom: 20px;
      }
    }
  }

  .apply-today-form {
    padding: 40px 0;
    background: linear-gradient(90deg, #1e1e20 5.87%, #000000 100%);

    .field-bottom span.checkbox-wrap > span {
      margin-bottom: 20px;
    }

    form .field-group input {
      height: 43px;
    }

    input[type="submit"] {
      height: 42px;
      font-size: 14px;
    }
  }
}
