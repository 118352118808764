.has-post-thumbnail .article-post .entry-header {
  padding: 40px 0 50px;
}

.article-post {
  margin-bottom: 0 !important;

  .entry-header {
    h1 {
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      max-width: 992px;
      margin: 15px auto 0;
      text-transform: initial;
    }
  }

  .entry-content {
    margin-top: 0;
    margin-bottom: 0 !important;
    .post-thumbnail {
      margin: 0;
      width: 100%;
      margin-bottom: 90px;

      img {
        margin-top: 0;
        max-width: 100% !important;
        max-height: 532px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.entry-content .post-image-text {
  margin: 90px 0;
  position: relative;
  z-index: 1;
}

.entry-content .content-rows {
  :first-child {
    margin-top: 0;
  }
}

.column-grid-item {
  display: flex;
  flex-wrap: wrap;

  &.item-aligment-left {
    flex-direction: row-reverse;

    .grid-item-details {
      padding-left: 60px;
    }
  }

  &.item-aligment-right {
    .grid-item-details {
      padding-right: 60px;
    }
  }

  .grid-item {
    flex: 0 0 50%;
    width: 50%;
  }

  .grid-item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 42px;
      margin-bottom: 50px;
      line-height: 42px;
    }

    .item-details {
      font-size: 16px;
      font-weight: 400;;
    }
  }

  .grid-item-img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.entry-content .post-quote-text {
  margin: 90px 0;
}

.post-quote-text {
  padding: 120px 0;
  max-width: 100% !important;
  background: linear-gradient(-90deg, #1e1e20 5.87%, #000000 100%);

  .quote {
    position: relative;
    font-size: 48px;
    line-height: 48px;
    font-family: "AktivGrotesk Ex Black Italic";
    text-align: center;
    color: var(--global--color-gold);

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      content: close-quote;
      right: 0;
    }

    &::before {
      content: open-quote;
      left: 0;
    }
  }

  h3 {
    font-size: 48px;
    line-height: 48px;
    position: relative;
    max-width: 620px;
    margin: 0 auto;
    letter-spacing: -2px;
  }
}

@media screen and (max-width: 1199px) {
  .entry-content .post-image-text {
    margin: 70px 0;
  }

  .entry-content .post-thumbnail {
    margin-bottom: 70px;
  }

  .entry-content .post-quote-text {
    margin: 70px 0;
    padding: 80px 0;
  }

  .column-grid-item {
    &.item-aligment-right {
      .grid-item-details {
        padding-right: 40px;
      }
    }

    &.item-aligment-left {
      .grid-item-details {
        padding-left: 40px;
      }
    }

    .grid-item-details h2 {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .has-post-thumbnail .article-post .entry-header {
    padding: 20px 0 30px;
  }

  .article-post {
    .column-grid-item {
      .grid-item {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
      }

      .grid-item-details {
        margin-bottom: 30px;
      }
    }
  }

  .post-quote-text h3 {
    font-size: 40px;
    line-height: 40px;
    max-width: 450px;
  }
}

@media screen and (max-width: 767px) {
  .article-post {
    .entry-content .post-thumbnail img {
      min-height: 486px;
      margin-bottom: 50px;
    }
  }

  .entry-content .post-image-text {
    margin: 50px 0;
  }

  .column-grid-item {
    .grid-item-details {
      text-align: center;

      h2 {
        font-size: 30px;
        line-height: 32px;
        max-width: 390px;
        margin: 0 auto 30px;
      }
    }

    .grid-item-img img {
      min-height: 278px;
    }
  }

  .entry-content .post-quote-text {
    margin: 50px 0;
    padding: 40px 0;
  }

  .post-quote-text h3 {
    font-size: 34px;
    line-height: 38px;
    max-width: 400px;
  }
}

@media screen and (max-width: 574px) {
  .article-post .entry-header p {
    font-size: 14px;
    line-height: 20px;
  }

  .column-grid-item {
    .grid-item-details {
      h2 {
        font-size: 24px;
        line-height: 24px;
        max-width: 300px;
        margin: 0 auto 20px;
      }

      .item-details {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .post-quote-text {
    h3 {
      font-size: 30px;
    }

    .quote::after,
    .quote::before {
      display: block;
      position: relative;
      transform: initial;
    }

    .quote::after {
      bottom: -25px;
      top: initial;
    }

    .quote::before {
      top: -7px !important;
    }
  }
}
