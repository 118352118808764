.wp-block-search {
  max-width: var(--responsive--aligndefault-width);

  &__button-only.aligncenter {
    .wp-block-search__inside-wrapper {
      justify-content: center;
    }
  }

  .wp-block-search__label {
    font-size: var(--form--font-size);
    font-weight: var(--form--label-weight);
    margin-bottom: calc(var(--global--spacing-vertical) / 3);
  }

  .wp-block-search__input {
    border: var(--form--border-width) solid var(--form--border-color);
    border-radius: var(--form--border-radius);
    color: var(--form--color-text);
    line-height: var(--form--line-height);
    max-width: inherit;
    margin-right: calc(-1 * var(--button--border-width));
    padding: var(--form--spacing-unit);

    &:focus {
      color: var(--form--color-text);
      border-color: var(--form--border-color);
    }

    .has-background & {
      border-color: var(
        --local--color-primary,
        var(--global--color-primary)
      ) !important;
    }
  }

  button.wp-block-search__button {
    margin-left: 0;
    line-height: 1;

    &.has-icon {
      padding: 6px calc(0.5 * var(--button--padding-horizontal));

      svg {
        width: 40px;
        height: 40px;
        fill: currentColor;
      }
    }

    &:hover,
    &:active {
      .has-background & {
        background-color: var(
          --local--color-background,
          var(--global--color-background)
        ) !important;
        color: var(
          --local--color-primary,
          var(--global--color-primary)
        ) !important;
      }

      .has-text-color & {
        color: var(
          --local--color-primary,
          var(--global--color-primary)
        ) !important;
      }
    }
  }

  &.wp-block-search__button-inside {
    .wp-block-search__inside-wrapper {
      background-color: var(--global--color-white);
      border: var(--form--border-width) solid var(--form--border-color);
      border-radius: var(--form--border-radius);
      padding: var(--form--border-width);

      .has-background & {
        border-color: var(
          --local--color-primary,
          var(--global--color-primary)
        ) !important;
      }

      .wp-block-search__input {
        margin-left: 0;
        margin-right: 0;
        padding-left: var(--form--spacing-unit);

        // Add outline for focus styles to override default
        &:focus {
          color: var(--form--color-text);
          outline-offset: -2px;
          outline: 2px dotted var(--form--border-color);
        }
      }

      button.wp-block-search__button {
        padding: var(--button--padding-vertical)
          var(--button--padding-horizontal);

        // Search button always needs black contrast against white form background
        &:hover {
          color: var(--global--color-dark-gray);
        }

        .is-dark-theme & {
          color: var(--global--color-dark-gray);

          &:hover {
            background-color: var(--global--color-dark-gray);
            color: var(--global--color-white);
          }
        }

        &.has-icon {
          padding: 6px calc(0.5 * var(--button--padding-horizontal));
        }
      }
    }
  }
}

.wp-block-search__button {
  box-shadow: none;
}
