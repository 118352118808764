@import "./colors";

$primary-color: $pepsi-blue;
$secondary-color: $light-orange;
$action-color: $light-green;
$disabled-color: $gray;
$error-color: $red;
$text-color: $dark-gray;
$description-color: $light-gray;
$promo-color: $lightest-blue;

$header-promo-height: 28px;
$header-height: 60px;
$header-height-mobile: 60px;

$margin-base: 10px;
$padding-base: 10px;

$font-primary: "font-local", "font-primary", "Helvetica Neue", "Helvetica", "Arial",
  sans-serif;
$font-secondary: "font-secondary", "Helvetica Neue", "Helvetica", "Arial",
  sans-serif;

$z-index-modal: 10001;
$z-index-header: $z-index-modal - 1;

// BREAKPOINT WIDTHS
// simplified version of https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
$mobile-min: 320px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1024px;
$laptop-min: 1025px;

// BREAKPOINTS
@mixin mobile {
  @media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-laptop {
  @media (max-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$tablet-min}) {
    @content;
  }
}
