// LEAFLET STYLES

#map {
  height: 420px;
  padding: 10px;
}

.mapContainer {
  height: 420px;
}

.rockstarMarker:hover {
  opacity: 0.7;
}

/** POPUP STYLES */
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
}

.leaflet-popup-tip {
  display: none;
}

.leaflet-popup-content-wrapper {
  background-color: transparent;
  color: white;
  min-width: 250px;
}

.leaflet-popup-close-button {
  color: white !important;
  font-size: 20px !important;
}

.leaflet-popup-content {
  margin: 0;
}

// SMALL SLICK CSS
.slick-active {
  z-index: 999;
}

// SMALL SLICK CSS
.slick-active {
  z-index: 999;
}
