.search-no-results .page-content {
  margin-top: calc(3 * var(--global--spacing-vertical));
}

.no-search-results {
  padding-top: 40px;
  header {
    border-bottom: none;
    padding-bottom: 0;
  }

  p {
    padding: 0 20px 150px;
  }
}

.search-results {
  .page-header {
    padding-top: 30px;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;

    h1 {
      text-transform: uppercase;
      color: var(--global--color-white);
      padding-bottom: 30px;
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
    }
  }

  .search-result-count {
    display: none;
  }

  .article-list {
    margin-top: 60px;
    margin-bottom: 0;

    .related-content {
      font-size: 37px;
      line-height: 36px;
      color: var(--global--color-white);
      margin-bottom: 38px;
      text-transform: uppercase;
    }

    article {
      padding: 30px 0 40px;
      border-top: 1px dashed var(--global--color-white);

      &:last-of-type {
        padding-bottom: 0;
      }

      header {
        position: relative;
        margin-top: 0;
        padding-right: 110px;

        a {
          font-size: 14px;
          position: absolute;
          right: 0;
          margin-top: 0;
          top: 50%;
          transform: translateY(-50%);
          text-decoration: underline;

          &:hover {
            color: var(--global--color-gold);
          }
        }
      }

      .entry-content {
        margin-bottom: 0;
        margin-top: 0;

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }
      }
    }

    .post-thumbnail {
      display: none;
    }

    .entry-header {
      margin-bottom: 12px;

      h2 {
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
      }
    }
  }

  .search-pagination {
    margin: 50px auto 30px;

    .pagination {
      border-top-color: var(--global--color-white);
      margin: 0;
      max-width: 100%;
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    .page-numbers.current {
      color: var(--global--color-gold);
    }
  }
}

@media screen and (max-width: 1199px) {
  .search-results {
    .page-header h1 {
      font-size: 44px;
      padding-bottom: 25px;
    }

    .article-list .related-content {
      font-size: 30px;
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 991px) {
  .search-results {
    .article-list {
      margin-top: 40px;

      .entry-header h2 {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .search-results {
    .page-header {
      padding-top: 15px;

      h1 {
        padding-bottom: 20px;
      }
    }

    .article-list {
      .related-content {
        font-size: 24px;
        margin-bottom: 35px;
        text-align: center;
      }

      .entry-header h2 {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .search-pagination {
      margin: 50px 0;

      .pagination .nav-links > *,
      .comments-pagination .nav-links > * {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .search-results {
    .page-header {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }

      p {
        font-size: 14px;
      }
    }

    .article-list {
      .entry-header h2 {
        font-size: 18px;
        margin-bottom: 12px;
      }

      .related-content {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
      }

      article {
        padding: 20px 0 25px;

        header a {
          font-size: 11px;
        }

        .entry-content p {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}
