h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  clear: both;
  font-family: var(--heading--font-family);
  font-weight: var(--heading--font-weight);

  strong {
    font-weight: var(--heading--font-weight-strong);
  }
}

h1,
.h1 {
  font-size: var(--heading--font-size-h1);
  letter-spacing: var(--heading--letter-spacing-h1);
  line-height: var(--heading--line-height-h1);
}

h2,
.h2 {
  font-size: var(--heading--font-size-h2);
  letter-spacing: var(--heading--letter-spacing-h2);
  line-height: var(--heading--line-height-h2);
}

h3,
.h3 {
  font-size: var(--heading--font-size-h3);
  letter-spacing: var(--heading--letter-spacing-h3);
  line-height: var(--heading--line-height-h3);
}

h4,
.h4 {
  font-size: var(--heading--font-size-h4);
  font-weight: var(--heading--font-weight-strong);
  letter-spacing: var(--heading--letter-spacing-h4);
  line-height: var(--heading--line-height-h4);
}

h5,
.h5 {
  font-size: var(--heading--font-size-h5);
  font-weight: var(--heading--font-weight-strong);
  letter-spacing: var(--heading--letter-spacing-h5);
  line-height: var(--heading--line-height-h5);
}

h6,
.h6 {
  font-size: var(--heading--font-size-h6);
  font-weight: var(--heading--font-weight-strong);
  letter-spacing: var(--heading--letter-spacing-h6);
  line-height: var(--heading--line-height-h6);
}
