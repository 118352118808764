hr {
  border-style: none;
  border-bottom: var(--separator--height) solid var(--separator--border-color);
  clear: both;
  margin-left: auto;
  margin-right: auto;

  &.wp-block-separator {
    border-bottom: var(--separator--height) solid var(--separator--border-color);
    opacity: 1;

    &:not(.is-style-dots):not(.alignwide) {
      max-width: var(--responsive--aligndefault-width);
    }

    &:not(.is-style-dots) {
      &.alignwide {
        max-width: var(--responsive--alignwide-width);
      }

      &.alignfull {
        max-width: var(--responsive--alignfull-width);
      }
    }

    /**
		 * Block Options
		 */
    &.is-style-rockstarenergy-separator-thick {
      border-bottom-width: calc(3 * var(--separator--height));
    }

    &.is-style-dots {
      &.has-background,
      &.has-text-color {
        background-color: transparent !important;

        &:before {
          color: currentColor !important;
        }
      }

      &:before {
        color: var(--separator--border-color);
        font-size: var(--global--font-size-xl);
        letter-spacing: var(--global--font-size-sm);
        padding-left: var(--global--font-size-sm);
      }
    }

    .has-background &,
    [class*="background-color"] &,
    [style*="background-color"] &,
    .wp-block-cover[style*="background-image"] & {
      border-color: currentColor;
    }
  }
}
