.wp-block-cover,
.wp-block-cover-image {
  &:not(.alignwide):not(.alignfull) {
    clear: both;
  }

  &.alignfull {
    margin-top: 0;
    margin-bottom: 0;
  }

  background-color: var(--cover--color-background);
  min-height: var(--cover--height);
  margin-top: inherit;
  margin-bottom: inherit;

  .wp-block-cover__inner-container,
  .wp-block-cover-image-text,
  .wp-block-cover-text {
    color: currentColor; // Uses text color specified with background-color options in 07-utilities\color-palette.scss
    margin-top: var(--global--spacing-vertical);
    margin-bottom: var(--global--spacing-vertical);

    a:not(.wp-block-button__link):not(.wp-block-file__button) {
      color: currentColor;
    }

    .has-link-color a {
      color: var(--wp--style--color--link, var(--global--color-primary));
    }
  }

  /* default & custom background-color */
  &:not([class*="background-color"]) {
    .wp-block-cover__inner-container,
    .wp-block-cover-image-text,
    .wp-block-cover-text {
      color: var(--cover--color-foreground);
    }
  }

  /* Treating H2 separately to account for legacy /core styles */
  h2 {
    font-size: var(--heading--font-size-h2);
    letter-spacing: var(--heading--letter-spacing-h2);
    line-height: var(--heading--line-height-h2);
    max-width: inherit; // undo opinionated styles
    text-align: inherit; // undo opinionated styles
    padding: 0;

    &.has-text-align-left {
      text-align: left;
    }

    &.has-text-align-center {
      text-align: center;
    }

    &.has-text-align-right {
      text-align: right;
    }
  }

  .wp-block-cover__inner-container {
    width: calc(100% - calc(2 * var(--global--spacing-vertical)));

    > * {
      margin-top: calc(0.666 * var(--global--spacing-vertical));
      margin-bottom: calc(0.666 * var(--global--spacing-vertical));

      @include media(mobile) {
        margin-top: var(--global--spacing-vertical);
        margin-bottom: var(--global--spacing-vertical);
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.alignleft,
  &.alignright {
    margin-top: 0;

    > * {
      margin-top: calc(2 * var(--global--spacing-vertical));
      margin-bottom: calc(2 * var(--global--spacing-vertical));
      padding-left: var(--global--spacing-horizontal);
      padding-right: var(--global--spacing-horizontal);
      width: 100%;
    }
  }

  &.has-left-content,
  &.has-right-content {
    justify-content: center;
  }

  /* Block Styles */
  &.is-style-rockstarenergy-border {
    border: calc(3 * var(--separator--height)) solid var(--global--color-border);
  }

  /* The background color class is used just for the overlay, and does not need to be applied to the inner container. */
  &[class*="-background-color"][class] .wp-block-cover__inner-container {
    background-color: unset;
  }
}
