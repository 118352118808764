.singular .entry-header {
  margin: 0 auto;
  padding: 100px 40px 0px 40px;
  text-align: center;
  text-transform: uppercase;
  max-width: 1600px;
  .entry-title {
    font-family: "AktivGrotesk Ex Black Italic";
  }
  @media screen and (max-width: 767px) {
    padding: 50px 20px 0 20px;
  }
}

.home .entry-header {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.singular .has-post-thumbnail .entry-header {
  border-bottom: none;
  padding-bottom: calc(1.3 * var(--global--spacing-vertical));
  margin-bottom: 0;
}
