// Gutenberg text color options

.has-black-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-black, #000000);
    color: var(--local--color-primary);
  }
  color: var(--global--color-black);
}

.has-gray-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-gray, #000000);
    color: var(--local--color-primary);
  }
  color: var(--global--color-gray);
}

.has-dark-gray-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-dark-gray, #000000);
    color: var(--local--color-primary);
  }
  color: var(--global--color-dark-gray);
}

.has-green-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-green, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-green);
}

.has-blue-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-blue, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-blue);
}

.has-purple-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-purple, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-purple);
}

.has-red-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-red, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-red);
}

.has-orange-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-orange, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-orange);
}

.has-yellow-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-yellow, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-yellow);
}

.has-white-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-primary: var(--global--color-white, #ffffff);
    color: var(--local--color-primary);
  }
  color: var(--global--color-white);
}

// Gutenberg background-color options
.has-background {
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: currentColor;
  }
}

.has-black-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-black, #000000);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-black);
}

.has-dark-gray-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-dark-gray, #000000);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-dark-gray);
}

.has-gray-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-gray, #000000);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-gray);
}

.has-light-gray-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-light-gray, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-light-gray);
}

.has-green-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-green, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-green);
}

.has-blue-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-blue, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-blue);
}

.has-purple-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-purple, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-purple);
}

.has-red-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-red, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-red);
}

.has-orange-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-orange, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-orange);
}

.has-yellow-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-yellow, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-yellow);
}

.has-white-background-color[class] {
  // Localize CSS-variables to limit relationship scope
  > [class*="__inner-container"] {
    --local--color-background: var(--global--color-white, #ffffff);
    background-color: var(--local--color-background);
  }
  background-color: var(--global--color-white);
}

.has-background:not(.has-text-color) {
  &.has-black-background-color[class],
  &.has-gray-background-color[class],
  &.has-dark-gray-background-color[class] {
    color: var(--global--color-white);

    // Localize CSS-variables to limit relationship scope
    > [class*="__inner-container"] {
      --local--color-primary: var(--global--color-background, #ffffff);
      // Reverse the local foreground color in darkmode
      .is-dark-theme & {
        --local--color-primary: var(--global--color-primary, #000000);
      }
      color: var(--local--color-primary, var(--global--color-primary));
    }
  }

  &.has-green-background-color[class],
  &.has-blue-background-color[class],
  &.has-purple-background-color[class],
  &.has-red-background-color[class],
  &.has-orange-background-color[class],
  &.has-yellow-background-color[class],
  &.has-white-background-color[class] {
    color: var(--global--color-dark-gray);

    // Localize CSS-variables to limit relationship scope
    > [class*="__inner-container"] {
      --local--color-primary: var(--global--color-primary, #000000);
      // Reverse the local foreground color in darkmode
      .is-dark-theme & {
        --local--color-primary: var(--global--color-background, #ffffff);
      }
      color: var(--local--color-primary, var(--global--color-primary));
    }
  }
}

// Custom gradients
.has-purple-to-yellow-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-purple),
    var(--global--color-yellow)
  );
}

.has-yellow-to-purple-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-yellow),
    var(--global--color-purple)
  );
}

.has-green-to-yellow-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-green),
    var(--global--color-yellow)
  );
}

.has-yellow-to-green-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-yellow),
    var(--global--color-green)
  );
}

.has-red-to-yellow-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-red),
    var(--global--color-yellow)
  );
}

.has-yellow-to-red-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-yellow),
    var(--global--color-red)
  );
}

.has-purple-to-red-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-purple),
    var(--global--color-red)
  );
}

.has-red-to-purple-gradient-background {
  background: linear-gradient(
    160deg,
    var(--global--color-red),
    var(--global--color-purple)
  );
}
