@import "variables";

.popupItem {
  display: flex;
  justify-content: flex-start;
  padding: 12px 0;

  svg {
    margin-top: 2px;
    path {
      fill: #1E1E1E;
    }
  }
}

.title {
  padding-bottom: 5px;
}

.popupItem > div:first-child {
  width: 100px;
  height: 100px;
  margin: 0 30px 0 0;
  flex-shrink: 0;

  @media screen and (min-width: 768px) {
    width: 120px;
    height: 120px;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.image {
  /* Hack to emulate objectFit: contain in IE: https://stackoverflow.com/a/41226965 */
  max-width: 100%;
  max-height: 100%;
  /* alt text styling */
  word-break: break-word;
}

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  letter-spacing: 0px;
}

.qty {
  font-size: 14px;
}
