@import "variables";

.product-content-area {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.rockstar-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0 70px;
  position: relative;

  .rockstar-product-gallery {
    display: flex;
    opacity: 1;
    width: 50%;
    margin-bottom: 0;
  }

  .rockstar-product-viewport {
    max-width: 100%;
    flex: 0 0 75%;
    max-width: 75%;

    .slick-slide {
      display: flex;
      justify-content: center;
    }

    img {
      width: 100%;
      max-width: 100%;
      background: #EFE9D6;
    }
  }

  .rockstar-product-thumb {
    display: inline-flex;
    flex-direction: column;
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 50px;

    .product-thumb-img {
      cursor: pointer;
      width: 100% !important;
      margin-bottom: 20px;

      img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 122px;
        background: #EFE9D6
      }
    }
  }
}

.pdp-back-btn {
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: -40px;
  border: 1px solid var(--global--color-focus-tertiary);
  color: var(--global--color-focus-tertiary);
  padding: 4px 18px 2px 30px;
  border-radius: 12px;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid transparent; /* Size of the chevron */
    border-bottom: 5px solid transparent; /* Size of the chevron */
    border-right: 5px solid var(--global--color-focus-tertiary); /* Color and width of the chevron */
    // height: 10px;
    // width: 6px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s all;
  }

  &:hover {
    &::after {
      left: 14px;
      transition: 0.3s all;
    }
  }
}

.rockstar-product-summary {
  h1 {
    font-size: 46px;
    line-height: 50px;
    text-transform: uppercase;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }

  .product-nutrition {
    font-family: $font-secondary;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: underline;
    color: var(--global--color-focus-tertiary);
    font-size: 12px;
    line-height: 20px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .product-subscription-disclosure {
    font-size: 12px;

    span {
      font-size: 14px;
    }

    a {
      text-decoration: underline;
    }
  }

  .product-short-description {
    margin: 40px 0 16px;
    p {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0;
      color: var(--global--color-focus-tertiary);
      a {
        color: var(--global--color-focus-tertiary);
      }
    }

    .product-nutrition {
      display: block;
      font-size: 12px;
      line-height: normal;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 16px;
      padding-top: 0;
      margin: 25px 0;
      text-transform: uppercase;
    }
  }

  .price {
    text-transform: uppercase;
    font-size: 32px;
    line-height: normal;
    font-family: "AktivGrotesk Ex Black Italic";
    font-weight: 900;
    margin-bottom: 30px;

    span {
      display: inline-block;
      margin-right: 10px;

      &.originalPrice {
        text-decoration: line-through;
        color: #989898;
      }
    }
  }

  .product-ingredient-block {
    margin: 20px 0;

    li {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-bottom: 15px;
    }

    img {
      height: auto;
      max-width: 100%;
      margin-right: 15px;
    }

    p {
      font-size: 13px;
      line-height: 20px;
      font-family: "AktivGrotesk Ex Black Italic";
    }
  }

  .product-quantity-field {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .delivery-location {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 20px;
    }

    p {
      letter-spacing: 1px;

      span {
        text-decoration: underline;
      }
    }
  }

  .subscription-checkbox {
    display: flex;
    margin: 24px 0;

    .onetime-purchase,
    .subscribe-purchase {
      display: flex;
      align-items: center;
      height: 100%;
    }

    label {
      font-size: 12px;
      padding-left: 18px;
      margin-bottom: 0;
      text-transform: uppercase;
      cursor: pointer;
    }

    .subscribe-purchase-wrapper {
      margin-left: 90px;
      height: 100%;
    }
  }

  .subscribe-cta {
    display: flex;

    li {
      list-style-type: none;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        display: inline-block;
        font-size: 12px;
        border-radius: 12px;
        border: 1px solid var(--global--color-white);
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 4px 15px 2px;
        transition: 0.3s all;

        &:hover {
          color: var(--global--color-black);
          background-color: var(--global--color-white);
          transition: 0.3s all;
        }
      }
    }
  }

  .add-to-cart,
  .find-in-store {
    display: block;
    width: 100%;
    background-color: #cf9c43 !important;
    color: var(--global--color-background);
    font-size: 18px;
    font-family: "AktivGrotesk Ex Black Italic";
    text-transform: uppercase;
    padding: 0 10px !important;
    max-height: 65px;
    transition: 0.3s all;
    margin-top: 30px;
    border: 2px solid #cf9c43;

    @include mobile {
      font-size: 16px;
    }
    @include not-laptop{
      font-size: 20px
    }

    &:hover {
      background-color: transparent !important;
      color: #cf9c43;
      transition: 0.3s all;
    }

    &:focus {
      text-decoration: none;
      outline: none;
    }
  }
}

.product-variations {
  position: relative;
  border: none;
  padding: 0;
  margin-bottom: 20px;
  max-width: 522px;

  &::after {
    content: "";
    position: absolute;
    justify-self: end;
    width: 16px;
    height: 9px;
    background-color: #fff;
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    z-index: 2;
    grid-area: select;
    right: 0;
    top: 47%;
    transform: translate(-15px, -50%);
    pointer-events: none;
  }

  .reset_variations {
    display: none !important;
  }

  select {
    display: none !important;
  }

  .select2-container--default {
    .selection,
    .select2-selection--single,
    .select2-selection__rendered {
      display: inline-block;
      width: 100%;
    }

    .select2-selection__rendered {
      padding: 8px 0 8px 18px;
    }

    .select2-selection--single {
      display: flex;
      background-color: transparent;
      border: none !important;
    }

    .selection {
      display: inline-flex;
    }

    .select2-selection__rendered {
      color: #fff;
      width: calc(100% - 40px);
      overflow: hidden;
    }

    .select2-selection__arrow {
      display: none;
    }
  }

  .select2-container--default {
    display: inline-block;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    border: 1px solid var(--global--color-white);
    border-radius: 35px !important;
    font-size: 16px;
    cursor: pointer;
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    color: #fff;
    font-family: "AktivGrotesk Ex Black Italic";
    text-transform: uppercase;
    background-image: none;
    overflow: hidden;
    max-width: 100%;

    &:focus {
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }

    option {
      color: #000;
    }
  }

  &.one-variation {
    display: flex;
    margin-bottom: 10px;

    .select2-container--default {
      pointer-events: none;
      border: none !important;
    }

    .select2-container--default .select2-selection__rendered {
      padding: 0;
    }

    &::after {
      display: none !important;
    }
  }
}

.rockstar-product-summary {
  width: 50%;
  padding-left: 100px;
}

.fancybox-button--zoom {
  display: none !important;
}

.fancybox-navigation {
  .fancybox-button {
    &:hover {
      background-color: var(--global--color-gold);
    }
  }

  .fancybox-button[disabled],
  .fancybox-button[disabled]:hover {
    background-color: #888;
    color: var(--global--color-white);
    opacity: 0.7;
  }
}

// Related Products //

.rockstar-related-products-container {
  max-width: 100%;
  background-color: #EFE9D6;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.rockstar-related-products {
  max-width: 1700px;
  width: 100%;
  padding-top: 0;
  margin: 0 !important;

  .related-title {
    font-family: "AktivGrotesk Ex Black Italic";
    text-align: center;
    text-transform: uppercase;
    color: var(--global--color-white);
    font-size: calc(1.65 * var(--global--font-size-lg));
    padding-bottom: 40px;
    margin-bottom: 30px;
    &.focus-text {
      color: var(--global--color-focus-tertiary);
    }
  }
}

.rockstar-products {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  word-break: break-word;
  min-width: 12vw;
  margin: 0 -13px;
}

.rockstar-modal {
  position: absolute;
  display: none;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  border: 6px solid var(--global--color-gold);

  .rockstar-modal-wrapper {
    position: relative;
  }

  .close {
    position: absolute;
    right: 2px;
    top: 0;
    z-index: 2;
    cursor: pointer;

    svg {
      width: 40px;
    }

    path {
      stroke: #000;
    }
  }

  &.nutrition-modal .rockstar-modal-body {
    width: 250px;

    img {
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }
}

.category-page {
  padding-top: 32px;
}

.rockstar-product-title {
  font-size: 58px;
  padding-top: 50px;
  margin-bottom: 30px;
}

.categories-section {
  margin-top: 0;
}

.category-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -12px;
  position: relative;

  .clear-all {
    display: none;
    position: absolute;
    right: 15px;
    top: 55%;
    transform: translateY(-50%);
    border: 1px solid var(--global--color-white);
    color: var(--global--color-white);
    padding: 4px 15px 2px;
    border-radius: 12px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
}

.category-filter {
  max-width: 260px;
  text-align: center;
  width: 100%;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  .category-filter-wrapper {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      justify-self: end;
      width: 16px;
      height: 9px;
      background-color: var(--global--color-white);
      -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      z-index: 2;
      grid-area: select;
      right: 0;
      top: 47%;
      transform: translate(-15px, -50%);
      pointer-events: none;
    }
  }

  select {
    display: none !important;
  }

  .select2-container--default {
    .selection,
    .select2-selection--single,
    .select2-selection__rendered {
      display: inline-block;
      width: 100%;
    }

    .select2-selection__rendered {
      padding: 8px 0 8px 18px;
      white-space: nowrap;
    }

    .selection {
      display: inline-flex;
    }

    .select2-selection--single {
      display: flex;
      background-color: transparent;
      border: none !important;
    }

    .select2-selection__rendered {
      color: var(--global--color-white);
      max-width: calc(100% - 40px);
      overflow: hidden;
    }

    .select2-selection__arrow {
      display: none;
    }
  }

  .select2-container--default {
    display: inline-block;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    border: 1px solid var(--global--color-white);
    border-radius: 35px !important;
    font-size: 16px;
    cursor: pointer;
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    color: var(--global--color-white);
    font-family: "AktivGrotesk Ex Black Italic";
    text-transform: uppercase;
    background-image: none;
    overflow: hidden;
    max-width: 100%;
    text-align: left;

    &:focus {
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }

    option {
      color: var(--global--color-black);
    }
  }
}

.search-products {
  margin: 60px 0;
}

.zipcode-modal-box {
  .modal-box .modal-box-body {
    max-height: 500px !important;
  }

  .model-title {
    font-size: 62px;
    line-height: 62px;
    letter-spacing: -3.86px;
    color: var(--global--color-black);
  }

  .zipcode-checker-info {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;

    p {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 1.5px;
      color: var(--global--color-black);
      margin-bottom: 35px;
    }

    input {
      font-size: 20px;
      height: 63px;
      width: 100%;
      border-color: var(--global--color-black);
      text-align: center;
      font-family: "AktivGrotesk Ex Black Italic";

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #e2e2e2;
      }
    }
  }

  button.button {
    font-size: 20px;
    text-transform: uppercase;
    font-family: "AktivGrotesk Ex Black Italic";
    width: 100%;
    margin-top: 15px;
    color: var(--global--color-black) !important;
    background-color: var(--global--color-gold) !important;
    transition: 0.3s all;

    &:hover {
      color: var(--global--color-gold) !important;
      border-color: var(--global--color-gold) !important;
      background-color: transparent !important;
      transition: 0.3s all;
    }
  }
}

.zipcode-product-result {
  display: none;

  .model-title {
    text-align: center;
    margin-bottom: 45px;
  }

  .rockstar-products .product-item .product-item-detail a {
    padding-top: 0;
  }

  .rockstar-products a h2,
  .product-flavor-name {
    color: var(--global--color-black) !important;
  }

  .rockstar-products .product-item .product-img {
    padding: 20px;

    img {
      max-width: 190px;
    }
  }
}

.no-data-found {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 34px;
  margin: 60px 0;
}

.find-in-store {
  margin-top: 10px !important;
}

.not-available {
  display: block;
  line-height: normal;
  padding-top: 5px;
  text-transform: uppercase;
}

@media screen and (max-width: 1800px) {

  .product-content-area {
    max-width: 1700px;
  }

  .zipcode-modal-box .model-title {
    font-size: 56px;
    line-height: 56px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1460px) {
  .category-filters.filter-active {
    padding-right: 140px;
  }

  .filter-active .category-filter {
    max-width: calc(25% - 24px);
    width: 260px;
  }
}

@media screen and (max-width: 1199px) {
  .rockstar-product {
    .rockstar-product-viewport {
      flex: 0 0 80%;
      max-width: 80%;
    }

    .rockstar-product-thumb {
      flex: 0 0 20%;
      max-width: 20%;
      padding-left: 20px;
    }
  }

  .rockstar-product-summary {
    padding-left: 50px;

    h1 {
      font-size: 50px;
      line-height: 48px;
      margin-bottom: 20px;
    }

    .subscription-checkbox .subscribe-purchase-wrapper {
      margin-left: 35px;
    }

    .add-to-cart {
      font-size: 18px;
      letter-spacing: -1.5px;
    }
  }

  .rockstar-products .product-item .product-price {
    font-size: 20px;
  }

  .rockstar-related-products {
    margin-top: 40px;

    .related-title {
      padding-bottom: 10px;
    }
  }

  .rockstar-product-title {
    font-size: 46px;
  }

  .zipcode-modal-box {
    .model-title {
      font-size: 52px;
      line-height: 52px;
      letter-spacing: -1.86px;
    }
  }

  .filter-active {
    .clear-all {
      top: unset;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      right: unset;
    }
  }

  .product-content-area {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 1023px) {
  .rockstar-products {
    margin: 0 -5px;

    li {
      width: calc(33.33% - 10px);
      margin: 0 5px 17px 5px;
    }

    .product-item .product-img {
      padding: 30px;
    }
  }

  .rockstar-product-title {
    font-size: 42px;
    padding-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .rockstar-product {
    .rockstar-product-gallery {
      display: block;
    }

    .rockstar-product-viewport {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 30px;
    }

    .rockstar-product-thumb {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%;
      padding-left: 0;

      .slick-track {
        display: flex;
      }

      .product-thumb-img {
        margin: 0 8px;
      }
    }
  }

  .rockstar-product-summary {
    padding-left: 30px;

    h1 {
      font-size: 40px;
      line-height: 38px;
      margin-bottom: 15px;
    }

    .subscription-checkbox {
      display: block;
      margin-bottom: 20px;

      .subscribe-purchase-wrapper {
        margin: 15px 0 0;
      }
    }

    .add-to-cart {
      font-size: 20px;
      max-height: 54px;
      margin-top: 25px;
    }
  }

  .rockstar-product-title {
    font-size: 40px;
    max-width: 500px;
    margin: 0 auto 30px;
  }

  .search-products {
    margin: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .product-content-area {
    padding-left: 20px;
    padding-right: 20px;
  }

  .rockstar-product {
    display: block;
    margin-top: 15px;

    .rockstar-product-gallery {
      width: 100%;
      max-width: 400px !important;
      margin: 0 auto 35px;
    }

    .rockstar-product-thumb {
      display: none;
    }

    .rockstar-product-summary {
      padding-left: 0;
      width: 100%;

      h1 {
        font-size: 35px;
      }

      .product-variations
        .select2-container--default
        .select2-selection__rendered {
        font-size: 14px;
        line-height: 23px;
      }

      .product-quantity-block .quantity-field {
        .input-text {
          font-size: 18px;
        }
      }

      .product-quantity-field {
        margin-bottom: 20px;
      }
    }
  }

  .pdp-back-btn {
    display: none;
  }

  .rockstar-modal {
    top: auto;
  }

  .rockstar-related-products .related-title {
    font-size: var(--global--font-size-lg);
    padding-bottom: 20px;
  }

  .rockstar-products {
    li {
      width: calc(50% - 10px);
    }

    .product-item {
      .product-item-detail {
        a {
          margin-top: 0;

          h2 {
            font-size: 14px;
          }
        }

        .product-flavor-name {
          font-size: 11px;
        }

        .item-bottom-details .product-nutrition {
          font-size: 8px;
        }

        .product-price {
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }

  .rockstar-product-title {
    font-size: 35px;
    max-width: 480px;
  }

  .category-filters {
    margin: 0 0 20px;

    &.filter-active {
      margin: 0 0 50px;
    }

    .category-filter {
      width: 47%;
      max-width: calc(50% - 24px);
    }
  }

  .category-filter .select2-container--default .select2-selection__rendered {
    font-size: 20px;
    line-height: 26px;
    padding: 6px 0 6px 15px;
  }

  .zipcode-modal-box {
    max-width: 380px;

    .model-title {
      font-size: 44px;
      line-height: 44px;
      letter-spacing: -1.86px;
    }

    .zipcode-checker-info {
      margin: 40px auto;

      input {
        font-size: 16px;
        height: 50px;
      }
    }

    button.button {
      font-size: 16px;
      padding: 10px 30px;
    }
  }

  .zipcode-modal-box .zipcode-product-result {
    margin: 20px auto 0;

    .model-title {
      margin-bottom: 30px;
    }

    .product-flavor-name {
      margin-bottom: 0 !important;
    }
  }
}

@media screen and (max-width: 575px) {
  .rockstar-products {
    position: relative;

    li {
      position: static;
    }
  }

  .rockstar-product-title {
    font-size: 24px;
    max-width: 320px;
    margin-bottom: 30px;
  }

  .category-filter .select2-container--default .select2-selection__rendered {
    font-size: 16px;
  }

  .category-filters {
    margin-bottom: 30px;

    &.filter-active {
      margin-bottom: 50px;
    }

    .category-filter {
      width: 100%;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }

  .zipcode-modal-box {
    margin: 20px auto;

    .modal-box-body {
      padding: 50px 20px 40px !important;
    }

    .zipcode-checker-info {
      margin: 20px 0;

      p {
        margin-bottom: 25px;
      }
    }

    .model-title {
      font-size: 32px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 374px) {
  .rockstar-product-summary .add-to-cart {
    font-size: 18px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .category-filters .clear-all {
      padding: 2px 20px;
    }

    .rockstar-product-summary .subscribe-cta li a {
      padding: 4px 15px;
    }
  }
}

.checkbox-container {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 50px;
}

.checkbox-text {
  font-size: 16px;
  margin: 0;
  color: var(--global--color-focus-tertiary);
  font-weight: 700;
}
