.wp-block-columns {
  &:not(.alignwide):not(.alignfull) {
    clear: both;
  }

  .wp-block-column {
    > * {
      margin-top: calc(0.66 * var(--global--spacing-vertical));
      margin-bottom: calc(0.66 * var(--global--spacing-vertical));

      @include media(mobile) {
        margin-top: var(--global--spacing-vertical);
        margin-bottom: var(--global--spacing-vertical);
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wp-block-column:not(:last-child) {
    margin-bottom: calc(0.66 * var(--global--spacing-vertical));

    @include media(mobile) {
      margin-bottom: var(--global--spacing-vertical);
    }

    @include media(desktop) {
      margin-bottom: 0;
    }
  }

  &.is-style-rockstarenergy-columns-overlap {
    justify-content: space-around;

    @include media(laptop) {
      .wp-block-column {
        &:nth-child(2n) {
          margin-left: calc(-2 * var(--global--spacing-horizontal));
          margin-top: calc(2.5 * var(--global--spacing-horizontal));
          z-index: 2;

          // Provide text-based child blocks with a default background color to ensure they're readable.
          > p,
          > h1,
          > h2,
          > h3,
          > h4,
          > h5,
          > h6,
          > ul,
          > ol,
          > pre {
            &:not(.has-background) {
              background-color: var(--global--color-background);
              padding: var(--global--spacing-unit);
            }
          }

          // Lists should still have their usual left padding.
          > ul:not(.has-background),
          > ol:not(.has-background) {
            padding-left: calc(2 * var(--global--spacing-horizontal));
          }

          &.is-vertically-aligned-center {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.alignfull {
    .wp-block-column {
      p:not(.has-background),
      h1:not(.has-background),
      h2:not(.has-background),
      h3:not(.has-background),
      h4:not(.has-background),
      h5:not(.has-background),
      h6:not(.has-background) {
        padding-left: var(--global--spacing-unit);
        padding-right: var(--global--spacing-unit);
      }
    }
  }
}
