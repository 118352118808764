.wp-block-code {
  border-color: var(--global--color-border);
  border-radius: 0;
  border-style: solid;
  border-width: 0.1rem;
  padding: var(--global--spacing-unit);

  code {
    color: var(--global--color-primary);
    white-space: pre;
    overflow-x: auto;
    display: block;
  }
}
