/**
 * Block Options
 */
.wp-block-button {
  // Target the default and filled button states.
  &:not(.is-style-outline) {
    .wp-block-button__link:not(:hover):not(:active) {
      // Text colors
      &:not(.has-text-color) {
        color: var(--global--color-background);

        // Nested
        .has-background & {
          color: var(
            --local--color-background,
            var(--global--color-background)
          );

          &.has-background {
            color: var(--global--color-primary);
          }
        }
      }

      // Background-colors
      &:not(.has-background) {
        background-color: var(--global--color-primary);

        // Nested
        .has-background & {
          background-color: var(
            --local--color-primary,
            var(--global--color-primary)
          );
        }
      }
    }

    // Hover Button color should match parent element foreground color
    .wp-block-button__link:hover,
    .wp-block-button__link:active {
      border-color: currentColor !important;
      background-color: transparent !important;
      color: inherit !important;
    }
  }

  // Outline Style.
  &.is-style-outline {
    .wp-block-button__link:not(:hover):not(:active) {
      // Border colors
      &:not(.has-text-color),
      &:not(.has-background),
      &.has-background {
        border-color: currentColor;
      }

      // Text colors
      &:not(.has-text-color) {
        color: var(--global--color-primary);

        // Nested
        .has-background & {
          color: var(--local--color-primary, var(--global--color-primary));
        }
      }

      &.has-background {
        // Nested
        .has-background &:not(.has-text-color) {
          color: inherit;
        }
      }

      // Background-colors
      &:not(.has-background) {
        background-color: transparent;
      }
    }

    .wp-block-button__link:hover,
    .wp-block-button__link:active {
      border-color: transparent !important;
      background-color: var(--global--color-primary) !important;
      color: var(--global--color-background) !important;

      .has-background & {
        background-color: var(
          --local--color-primary,
          var(--global--color-primary)
        ) !important;
        color: var(
          --local--color-background,
          var(--global--color-background)
        ) !important;
      }

      .has-text-color & {
        color: var(
          --local--color-background,
          var(--global--color-background)
        ) !important;
      }
    }
  }

  // Squared Style
  .is-style-squared .wp-block-button__link {
    border-radius: 0;
  }
}

.is-style-outline .wp-block-button__link[style*="radius"]:focus,
.wp-block-button a.wp-block-button__link[style*="radius"]:focus {
  outline-offset: 2px;
  outline: 2px dotted var(--button--color-background);
}

.block-btn .wp-block-button__link {
  font-size: 14px;
  border-radius: 18px;
  padding: 8px 34px;
  border: 1px solid;
  font-family: "AktivGrotesk Ex Black Italic";
}
