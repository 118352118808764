// Do we need to serve a font? Add the @font-face styles here.
@font-face {
  font-family: "AktivGrotesk Ex Bold Italic";
  font-weight: 500;
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/AktivGrotesk_XBdIt.woff2")
    format("woff2");
}

@font-face {
  font-family: "AktivGrotesk Std Bold";
  font-weight: 500;
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/AktivGrotesk_Std_Bd.woff2")
    format("woff2");
}

@font-face {
  font-family: "AktivGrotesk Std Regular";
  font-weight: 500;
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/AktivGrotesk_Std_Rg.woff2")
    format("woff2");
}

@font-face {
  font-family: "AktivGrotesk Ex Bold";
  font-weight: 500;
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/AktivGrotesk_XBd.woff2")
    format("woff2");
}

@font-face {
  font-family: "AktivGrotesk Ex Black";
  font-weight: 500;
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/AktivGroteskEx_Blk.woff2")
    format("woff2");
}

@font-face {
  font-family: "AktivGrotesk Ex Black Italic";
  font-weight: 500;
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/AktivGroteskEx_BlkIt.woff2")
    format("woff2");
}

@font-face {
  font-family: "Aktiv Grotesk Ex Black";
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/Aktiv-Grotesk-Ex-Black.eot");
  src: url("https://assets.decovostatic.com/rockstar-energy/fonts/Aktiv-Grotesk-Ex-Black.eot?#iefix")
      format("embedded-opentype"),
    url("https://assets.decovostatic.com/rockstar-energy/fonts/Aktiv-Grotesk-Ex-Black.woff2")
      format("woff2"),
    url("https://assets.decovostatic.com/rockstar-energy/fonts/Aktiv-Grotesk-Ex-Black.woff")
      format("woff"),
    url("https://assets.decovostatic.com/rockstar-energy/fonts/Aktiv-Grotesk-Ex-Black.svg#Aktiv Grotesk Ex Black")
      format("svg");
  font-weight: 900;
  font-style: normal;
  font-stretch: expanded;
  unicode-range: U+0020-2084;
}
