.site-main {
  .lifestyle-block {
    margin-bottom: 70px;

    h2 {
      margin: 60px 0 40px;
      color: var(--global--color-white);
      text-transform: uppercase;
    }

    .plp-lifestyle-block {
      display: flex;
      align-items: center;
      padding: 15px;
      border: 1px solid var(--global--color-white);
      margin-bottom: 75px;
    }

    .img-block {
      flex-basis: 60% !important;
    }

    .content-block {
      flex-basis: 40% !important;
      margin-left: 0;
      padding: 0 35px 0 50px;
    }

    .block-sub-title {
      margin-bottom: 0;
    }

    .block-main-title {
      margin: 10px 0 20px;
    }

    p:not(.block-sub-title) {
      max-width: 435px;
      margin: 0 0 35px;
    }
  }
}

.rockstar-modal {
  position: absolute;
  display: none;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  border: 6px solid var(--global--color-gold);

  .rockstar-modal-wrapper {
    position: relative;
  }

  .close {
    position: absolute;
    right: 2px;
    top: 0;
    z-index: 2;
    cursor: pointer;

    svg {
      width: 40px;
    }

    path {
      stroke: #000;
    }
  }

  &.nutrition-modal .rockstar-modal-body {
    width: 250px;
  }
}

.related-product-title {
  margin-bottom: 70px;
}

.product-top-list-row {
  display: none !important;
}

@media screen and (max-width: 1440px) {
  .site-main .lifestyle-block {
    .block-main-title {
      font-size: 38px;
    }

    .content-block {
      padding: 0 25px 0 30px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .product-template-default .insta-block {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1023px) {
  .site-main .lifestyle-block {
    .block-main-title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 15px;
    }

    .block-sub-title {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 5px;
    }

    p:not(.block-sub-title) {
      margin-bottom: 25px;
    }

    h2 {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 991px) {
  .site-main {
    .lifestyle-block {
      .plp-lifestyle-block {
        display: block;
      }

      .content-block {
        padding: 15px 0 0;
      }

      .img-block {
        margin-bottom: 0 !important;

        img {
          height: 475px;
          object-fit: cover;
          object-position: 30%;
        }
      }

      .block-main-title {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .plp-lifestyle-block {
    margin-bottom: 50px;
  }

  .site-main .lifestyle-block {
    h2 {
      font-size: 30px;
    }
  }

  .rockstar-modal {
    top: auto;
  }

  .site-main .lifestyle-block,
  .site-main .lifestyle-block h2 {
    margin-top: 50px;
  }
}

@media screen and (max-width: 575px) {
  .site-main .block-sub-title {
    font-size: 11px;
  }

  .site-main .lifestyle-block {
    p:not(.block-sub-title) {
      font-size: 10px;
      margin-bottom: 20px;
    }
  }

  .block-btn .wp-block-button__link {
    font-size: 11px;
    padding: 5px 22px;
  }

  .site-main .lifestyle-block .block-main-title {
    font-size: 25px;
    line-height: 30px;
  }

  .product-top-list .product-top-item h3 {
    font-size: 17px;
  }
}

@media screen and (max-width: 374px) {
  .site-main .lifestyle-block .block-main-title {
    font-size: 21px;
  }
}
