.page-template-cart-page {
  overflow-x: unset;
}

.body-div:not(.home) .site-promo {
  overflow-x: hidden;
}

.cart-title {
  text-transform: uppercase;
  color: var(--global--color-white);
  margin-bottom: 80px;
}

.cart-block {
  .cart-details {
    display: flex;
    margin-bottom: 70px;
  }

  h2 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 10px;
    text-transform: uppercase;

    a {
      color: var(--global--color-gold);
    }
  }

  .product-list {
    flex-basis: 100%;
    padding-right: 40px !important;

    li {
      display: flex;
      list-style-type: none;
      padding: 25px 0;
      border-top: 1px solid var(--global--color-white);
    }

    .product-image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      width: 100%;
      max-width: 234px;
      background: radial-gradient(
          50% 50% at 50% 50%,
          #313131 17.39%,
          #121212 100%
        ),
        #353637;

      img {
        max-width: 100%;
      }
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 50px;

    .cart-price {
      font-size: 13px;
      letter-spacing: 6px;
    }
  }

  .quantity-block {
    display: inline-flex;
    align-items: center;
    margin: 25px 0;

    .remove-btn {
      text-decoration: underline;
    }
  }

  .quantity-field {
    position: relative;
    display: inline-flex;
    padding: 0 30px;
    margin-right: 20px;
    border: 1px solid var(--global--color-focus-tertiary);
    border-radius: 35px;
    font-family: "AktivGrotesk Ex Black Italic";

    .plus-btn {
      font-size: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }

    .min-btn {
      font-size: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }

    .input-text {
      font-size: 20px;
      background-color: transparent;
      border: none;
      color: var(--global--color-focus-tertiary);
      text-align: center;
      padding: 6px 10px;
      width: 70px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      &:focus {
        text-decoration: none;
        outline: none;
      }
    }
  }

  .shipping-note {
    font-size: 16px;
    line-height: 16px;
  }

  .order-summary {
    padding: 20px;
    border: 1px solid var(--global--color-white);
    height: 100%;
    min-width: 450px;

    .summary-item {
      display: flex;
      justify-content: space-between;
      padding-bottom: 25px;
      font-size: 16px;
      font-weight: normal;
      text-transform: uppercase;

      &:first-of-type {
        margin-top: 28px;
      }

      &:last-of-type {
        padding: 30px 0 5px;
        border-top: 1px solid var(--global--color-white);
      }

      .summary-totle {
        font-size: 20px;
        line-height: 20px;
        font-family: "AktivGrotesk Ex Black Italic";
      }
    }
  }

  .checkout {
    text-align: center;
    margin-top: 20px;

    .btn {
      font-size: 20px;
      font-family: "AktivGrotesk Ex Black Italic";
      background-color: var(--global--color-gold);
      color: var(--global--color-black);
      width: 100%;
      border-radius: 0;
      padding: 10px 20px;

      &:hover {
        background-color: transparent;
        color: var(--global--color-gold);
        border-color: var(--global--color-gold);
      }
    }
  }
}

.modal-box {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  max-width: 100% !important;
  background: rgba(0, 0, 0, 0.63);
  z-index: 999;

  .modal-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .modal-box-body {
    position: relative;
    background: var(--global--color-white);
    width: 100%;
    max-width: 70%;
    padding: 50px;
    max-height: calc(100vh - 30px);
    overflow: auto;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 10px;
    color: transparent;
    -webkit-text-stroke: 1px var(--global--color-black);
    font-family: "AktivGrotesk Ex Black Italic";
    font-size: 17px;
    width: 56px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--global--color-black);
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background-color: #000;
      color: #fff;
      -webkit-text-stroke: 1px transparent;
      transition: 0.3s all;
    }
  }
}

.cart-modal {
  h2 {
    font-size: 32px;
    line-height: 32px;
  }

  .cart-left-info {
    font-size: 16px;
    color: var(--global--color-black);

    p {
      margin-bottom: 10px;
    }

    li {
      position: relative;
      list-style-type: none;
      padding: 4px 0 0 12px;

      &::before {
        content: "";
        height: 5px;
        width: 5px;
        background-color: var(--global--color-black);
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .cart-modal-contents {
    display: flex;
  }

  .left-content,
  .right-content {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .left-content {
    padding-right: 50px;
    border-right: 1px solid var(--global--color-black);
    .btn-square {
      width: 100%;
      max-width: 464px;
      margin-top: 30px;
    }
  }

  .right-content {
    font-size: 16px;
    padding-left: 50px;
    color: var(--global--color-black);

    .btn-square {
      color: var(--global--color-black);
      background-color: transparent;
      border-color: var(--global--color-black);
      width: 100%;
      max-width: 450px;
      margin: 30px 0;

      &:hover {
        background-color: var(--global--color-black);
        color: var(--global--color-white);
      }
    }

    .right-end-text a {
      color: var(--global--color-black);
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1360px) {
  .cart-block .order-summary {
    min-width: 410px;
  }

  .modal-box {
    .modal-box-body {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .cart-title {
    font-size: 36px;
    margin-bottom: 60px;
  }

  .cart-block {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }

    .order-summary {
      min-width: 350px;

      .summary-item .summary-totle {
        font-size: 17px;
      }
    }

    .product-list .product-image {
      max-width: 180px;
    }

    .product-info {
      padding-left: 30px;

      .cart-price {
        letter-spacing: 3px;
      }
    }

    .quantity-field .input-text {
      font-size: 17px;
      padding: 6px;
      width: 60px;
    }

    .checkout .btn {
      font-size: 17px;
    }
  }

  .modal-box {
    .modal-box-body {
      padding: 50px 40px 40px;
    }

    .left-content {
      padding-right: 40px;
    }

    .right-content {
      padding-left: 40px;
    }
  }
}

@media screen and (min-width: 992px) {
  .cart-block .order-summary {
    position: sticky;
    top: 100px;
  }
}

@media screen and (max-width: 991px) {
  .cart-block {
    .cart-details {
      flex-wrap: wrap;
    }

    .product-list {
      padding-right: 0 !important;
    }

    .order-summary {
      margin-top: 20px;
      min-width: 100%;
    }
  }

  .cart-modal {
    .modal-box-body {
      max-width: 67%;
    }

    .cart-modal-contents {
      flex-wrap: wrap;
    }

    .left-content,
    .right-content {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      border: none;
    }

    .left-content {
      padding-bottom: 30px;
    }

    .right-content {
      border-top: 1px solid var(--global--color-black);
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .cart-modal {
    .modal-box-body {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 575px) {
  .cart-title {
    font-size: 32px;
    margin-bottom: 25px;
    margin-top: 20px !important;
  }

  .cart-block {
    .product-list {
      li {
        padding: 17px 0;
      }

      .product-image {
        max-width: 140px;
        padding: 15px;
      }
    }

    .product-info {
      padding-left: 15px;

      .cart-price {
        font-size: 10px;
      }
    }

    h2 {
      font-size: 20px;
      line-height: 23px;
    }

    .order-summary {
      padding: 15px;

      h2 {
        font-size: 26px;
      }
    }

    .quantity-field {
      margin-right: 15px;

      .input-text {
        font-size: 12px;
        padding: 6px;
        width: 50px;
      }
    }

    .quantity-block .remove-btn {
      font-size: 10px;
    }

    .shipping-note {
      font-size: 10px;
      line-height: 14px;
    }
  }

  .cart-modal {
    h2 {
      font-size: 26px;
      line-height: 26px;
    }

    .cart-left-info {
      li,
      p {
        font-size: 13px;
        line-height: 15px;
      }

      li {
        margin-bottom: 5px;
      }
    }

    .btn.btn-primary.btn-square {
      font-size: 16px;
      padding: 12px 24px;
      margin-top: 20px;
    }

    .right-content {
      p {
        font-size: 13px;
        line-height: 15px;
      }

      .btn-square {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 424px) {
  .cart-block {
    .head-title h2 {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 5px;
    }

    .quantity-block {
      margin: 15px 0;
    }

    .product-list .product-image {
      height: 100%;
    }

    .order-summary {
      h2 {
        font-size: 21px;
      }
      .summary-item {
        padding-bottom: 15px;
        font-size: 13px;

        &:first-of-type {
          margin-top: 20px;
        }

        &:last-of-type {
          padding-top: 20px;
        }

        .summary-totle {
          font-size: 16px;
        }
      }
    }

    .checkout {
      margin-top: 12px;

      .btn {
        font-size: 14px;
        padding: 10px;
      }
    }
  }

  .cart-modal {
    .modal-box-body {
      padding: 50px 20px 30px;
    }

    h2 {
      font-size: 20px;
      line-height: 20px;
    }

    .btn.btn-primary.btn-square {
      font-size: 14px;
      padding: 10px 12px;
    }

    .right-content {
      padding-top: 20px;
    }

    .left-content {
      padding-bottom: 25px;
    }
  }
}
