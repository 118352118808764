.PreviewContainer {
  position: fixed;
  bottom: 0;
  width: 250px;
  background: green;
  z-index: 10000000;
  padding: 10px;
  display: block;
  text-align: center;
}

.PreviewTitle {
  margin-bottom: 10px;
}

.PreviewSubContainer {
  display: flex;
  flex-direction: column;
}

.PreviewButton {
  text-decoration: underline;
}