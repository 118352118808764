/* Variables */

// Vertical Rhythm Multiplier
$baseline-unit: 10px;

:root {
  /* Font Family */
  --global--font-primary: $font-primary;
  --global--font-secondary: var(
    "AktivGrotesk Std Bold",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif
  );
  --global--font-primary-italic: var(
    "AktivGrotesk Ex Bold Italic",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif
  );
  --global--font-primary-black-italic: var(
    "AktivGrotesk Ex Black Italic",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif
  );

  /* Font Size */
  --global--font-size-base: 1.25rem; // 20px / 16px
  --global--font-size-xxs: 0.75rem; // 16px / 16px
  --global--font-size-xs: 1rem; // 16px / 16px
  --global--font-size-sm: 1.125rem; // 18px / 16px
  --global--font-size-md: 1.25rem; // 20px / 16px
  --global--font-size-lg: 1.5rem; // 24px / 16px
  --global--font-size-xl: 2.75rem; // 36px / 16px
  --global--font-size-xxl: 4rem; // 64px / 16px
  --global--font-size-xxxl: 2.5rem; // 80px / 16px
  --global--font-size-page-title: var(--global--font-size-xl);
  --global--letter-spacing: normal;

  /* Line Height */
  --global--line-height-body: 1.7;
  --global--line-height-heading: 1.3;
  --global--line-height-page-title: 1.1;

  /* Headings */
  --heading--font-family: var(--global--font-primary-italic);

  --heading--font-size-h6: var(--global--font-size-xs);
  --heading--font-size-h5: var(--global--font-size-sm);
  --heading--font-size-h4: var(--global--font-size-lg);
  --heading--font-size-h3: calc(1.25 * var(--global--font-size-lg));
  --heading--font-size-h2: var(--global--font-size-xl);
  --heading--font-size-h1: var(--global--font-size-page-title);

  --heading--letter-spacing-h6: 0.05em;
  --heading--letter-spacing-h5: 0.05em;
  --heading--letter-spacing-h4: var(--global--letter-spacing);
  --heading--letter-spacing-h3: var(--global--letter-spacing);
  --heading--letter-spacing-h2: var(--global--letter-spacing);
  --heading--letter-spacing-h1: var(--global--letter-spacing);

  --heading--line-height-h6: var(--global--line-height-heading);
  --heading--line-height-h5: var(--global--line-height-heading);
  --heading--line-height-h4: var(--global--line-height-heading);
  --heading--line-height-h3: var(--global--line-height-heading);
  --heading--line-height-h2: var(--global--line-height-heading);
  --heading--line-height-h1: var(--global--line-height-page-title);

  --heading--margin-h1: var(--global--font-size-xs);

  --heading--font-weight: normal;
  --heading--font-weight-page-title: 300;
  --heading--font-weight-strong: 600;

  /* Block: Latest posts */
  --latest-posts--title-font-family: var(--heading--font-family);
  --latest-posts--title-font-size: var(--heading--font-size-h3);
  --latest-posts--description-font-family: var(--global--font-secondary);
  --latest-posts--description-font-size: var(--global--font-size-sm);

  --list--font-family: var(--global--font-secondary);
  --definition-term--font-family: var(--global--font-primary);

  /* Colors */
  --global--color-logo: #DDA946;
  --global--color-black: #000;
  --global--color-tertiary: #2C2925;
  --global--color-focus: #FAF6E8;
  --global--color-dark-gray: #28303d;
  --global--color-gray: #39414d;
  --global--color-light-gray: #f0f0f0;
  --global--color-disabled-gray: #cccccc;
  --global--color-black-gray: #6c6c6c;
  --global--color-green: #d1e4dd;
  --global--color-blue: #d1dfe4;
  --global--color-purple: #d1d1e4;
  --global--color-red: #e4d1d1;
  --global--color-orange: #e4dad1;
  --global--color-gold: #cf9c43;
  --global--color-yellow: #eeeadd;
  --global--color-white: #fff;
  --global--color-pink: #df1c63;
  --global--color-purple: #7958a8;
  --global--color-white-50: rgba(
    255,
    255,
    255,
    0.5
  ); // Used for disabled buttons
  --global--color-white-90: rgba(255, 255, 255, 0.9); // Used in form fields.

  --global--color-primary: var(
    --global--color-tertiary
  ); /* Body text color, site title, footer text color. */
  --global--color-focus-tertiary: var(--global--color-tertiary);
  --global--color-secondary: var(--global--color-gray); /* Headings */
  --global--color-primary-hover: var(--global--color-gold);
  --global--color-background: var(
    --global--color-black
  ); /* Mint, default body background */
  --global--color-border: var(
    --global--color-white-50
  ); /* Used for borders (separators) */

  /* Spacing */
  --global--spacing-unit: #{2 * $baseline-unit}; // 20px
  --global--spacing-measure: unset; // Use ch units here. ie: 60ch = 60 character max-width
  --global--spacing-horizontal: #{2.5 * $baseline-unit}; // 25px
  --global--spacing-vertical: #{3 * $baseline-unit}; // 30px.

  /* Elevation */
  --global--elevation: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);

  /* Forms */
  --form--font-family: var(--global--font-secondary);
  --form--font-size: var(--global--font-size-sm);
  --form--line-height: var(--global--line-height-body);
  --form--color-text: var(
    --global--color-dark-gray
  ); // Text color in input fields is always dark over light background.
  --form--color-ranged: var(--global--color-secondary);
  --form--label-weight: 500;
  --form--border-color: var(--global--color-secondary);
  --form--border-width: 3px;
  --form--border-radius: 0;
  --form--spacing-unit: calc(0.5 * var(--global--spacing-unit));

  /* Cover block */
  --cover--height: calc(15 * var(--global--spacing-vertical));
  --cover--color-foreground: var(--global--color-white);
  --cover--color-background: var(--global--color-black);

  /* Buttons */
  // Colors
  --button--color-text: var(--global--color-background);
  --button--color-text-hover: var(--global--color-secondary);
  --button--color-text-active: var(--global--color-secondary);
  --button--color-background: var(--global--color-secondary);
  --button--color-background-active: var(--global--color-background);
  // Fonts
  --button--font-family: var(--global--font-primary);
  --button--font-size: var(--global--font-size-base);
  --button--font-weight: 500;
  --button--line-height: 1.5;
  // Borders
  --button--border-width: 3px;
  --button--border-radius: 0;
  // Spacing
  --button--padding-vertical: 15px;
  --button--padding-horizontal: calc(2 * var(--button--padding-vertical));

  /* entry */
  --entry-header--color: var(--global--color-primary);
  --entry-header--color-link: currentColor;
  --entry-header--color-hover: var(--global--color-primary-hover);
  --entry-header--color-focus: var(--global--color-secondary);
  --entry-header--font-size: var(--heading--font-size-h2);
  --entry-content--font-family: var(--global--font-secondary);
  --entry-author-bio--font-family: var(--heading--font-family);
  --entry-author-bio--font-size: var(--heading--font-size-h4);

  /* Header */
  --branding--color-text: var(--global--color-primary);
  --branding--color-link: var(--global--color-primary);
  --branding--color-link-hover: var(--global--color-secondary);
  --branding--title--font-family: var(--global--font-primary);
  --branding--title--font-size: var(--global--font-size-lg);
  --branding--title--font-size-mobile: var(--heading--font-size-h4);
  --branding--title--font-weight: normal;
  --branding--title--text-transform: uppercase;
  --branding--description--font-family: var(--global--font-secondary);
  --branding--description--font-size: var(--global--font-size-sm);
  --branding--description--font-family: var(--global--font-secondary);

  --branding--logo--max-width: 300px;
  --branding--logo--max-height: 100px;
  --branding--logo--max-width-mobile: 96px;
  --branding--logo--max-height-mobile: 96px;

  /* Main navigation */
  --primary-nav--font-family: var(--global--font-primary-italic);
  --primary-nav--font-family-mobile: var(--global--font-primary-italic);
  --primary-nav--font-size: var(--global--font-size-md);
  --primary-nav--font-size-sub-menu: var(--global--font-size-xs);
  --primary-nav--font-size-mobile: var(--global--font-size-sm);
  --primary-nav--font-size-sub-menu-mobile: var(--global--font-size-sm);
  --primary-nav--font-size-button: var(--global--font-size-lg);
  --primary-nav--font-style: normal;
  --primary-nav--font-style-sub-menu-mobile: normal;
  --primary-nav--font-weight: normal;
  --primary-nav--font-weight-button: 500;
  --primary-nav--color-link: var(--global--color-primary);
  --primary-nav--color-link-hover: var(--global--color-primary-hover);
  --primary-nav--color-text: var(--global--color-primary);
  --primary-nav--padding: calc(0.66 * var(--global--spacing-unit));
  --primary-nav--border-color: var(--global--color-primary);

  /* Pagination */
  --pagination--color-text: var(--global--color-primary);
  --pagination--color-link-hover: var(--global--color-primary-hover);
  --pagination--font-family: var(--global--font-secondary);
  --pagination--font-size: var(--global--font-size-lg);
  --pagination--font-weight: normal;
  --pagination--font-weight-strong: 600;

  /* Footer */
  --footer--color-text: var(--global--color-primary);
  --footer--color-link: var(--global--color-primary);
  --footer--color-link-hover: var(--global--color-primary-hover);
  --footer--font-family: var(--global--font-primary);
  --footer--font-size: var(--global--font-size-sm);

  /* Block: Pull quote */
  --pullquote--font-family: var(--global--font-primary);
  --pullquote--font-size: var(--heading--font-size-h3);
  --pullquote--font-style: normal;
  --pullquote--letter-spacing: var(--heading--letter-spacing-h4);
  --pullquote--line-height: var(--global--line-height-heading);
  --pullquote--border-width: 3px;
  --pullquote--border-color: var(--global--color-primary);
  --pullquote--color-foreground: var(--global--color-primary);
  --pullquote--color-background: var(--global--color-background);

  --quote--font-family: var(--global--font-secondary);
  --quote--font-size: var(--global--font-size-md);
  --quote--font-size-large: var(--global--font-size-xl);
  --quote--font-style: normal;
  --quote--font-weight: 700;
  --quote--font-weight-strong: bolder;
  --quote--font-style-large: normal;
  --quote--font-style-cite: normal;
  --quote--line-height: var(--global--line-height-body);
  --quote--line-height-large: 1.35;

  --separator--border-color: var(--global--color-border);
  --separator--height: 1px;

  /* Block: Table */
  --table--stripes-border-color: var(--global--color-light-gray);
  --table--stripes-background-color: var(--global--color-light-gray);
  --table--has-background-text-color: var(--global--color-dark-gray);

  /* Widgets */
  --widget--line-height-list: 1.9;
  --widget--line-height-title: 1.4;
  --widget--font-weight-title: 700;
  --widget--spacing-menu: calc(0.66 * var(--global--spacing-unit));

  /* Admin-bar height */
  --global--admin-bar--height: 0px;

  /* Container-width */
  --global--container-width: 1600px;
}

.admin-bar {
  --global--admin-bar--height: 32px;

  @media only screen and (max-width: 782px) {
    --global--admin-bar--height: 46px;
  }
}

@media only screen and (min-width: 652px) {
  // Not using the mixin because it's compiled after this file
  :root {
    --global--font-size-xl: 2.5rem; // 40px / 16px
    --global--font-size-xxl: 6rem; // 96px / 16px
    --global--font-size-xxxl: 9rem; // 144px / 16px
    --heading--font-size-h3: 2rem; // 32px / 16px
    --heading--font-size-h2: 3rem; // 48px / 16px
  }
}
