@import "variables";

.wrapper {
  width: 100%;
  max-width: 476px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
  }

  button {
    display: block;
    width: 100%;
    margin: 0 0 10px;
    height: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  div h2 {
    margin: 0;
  }

  h2.modalHeader {
    font-size: 20px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
      font-size: 26px;
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'%3E%3Ccircle cx='15.3014' cy='15.6777' r='13.5' stroke='%23C49952' stroke-width='3'/%3E%3Cpath d='M7.40662 15.2596L13.1758 22.7828L22.4066 8.57227' stroke='%23C49952' stroke-width='4' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      display: inline-block;
      margin-right: 7px;

      @media screen and (min-width: 768px) {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }

  h3 {
    color: black;
    text-transform: uppercase;
    font-size: 16px;
  
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }

  .buttonOutline {
    background-color: #FFF;
    border: 2px solid #000;
    color: #000;
  }

  .recommendations {
    border-top: 1px solid #CCCCCC;
    padding-top: 20px;
    margin-top: 20px;
    letter-spacing: 0px;

    @media screen and (min-width: 768px) {
      padding-top: 25px;
    }

    .upsellContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      > * {
        width: calc(50% - 15px);

        > div {
          height: 100%;
        }
      }

      div {
        margin: 0;
        flex-direction: column;
        color: #1E1E1E;
      }

      // Users should not be able to click through to products on upsell modal
      a {
        pointer-events: none;
      }

      figure {
        margin: 0 auto;
      }

      h2 {
        font-size: 16px;
        margin: 5px 0 15px;
      }

      button {
        margin-top: 15px;
        background-color: #FFF;
        border: 2px solid #000;
      }
    }
  }
}