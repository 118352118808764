.site-main .athletes-page {
  .entry-header {
    padding-top: 80px;
    margin-top: 0;

    h1 {
      text-transform: uppercase;
    }
  }
}

.custom-post-with-filter {
  margin-top: 50px;

  .filter-list {
    text-align: center;
    ul {
      display: inline-flex;
      width: auto;
      max-width: 100%;
    }
  }
}

.filter-custom-contents {
  display: flex;
  margin: 0 -15px 100px;
  flex-wrap: wrap;
}

.filter-custom-content {
  display: none;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 15px;
  margin-bottom: 50px;

  .filter-custom-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .feature-title-block {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .feature-title-block {
    span {
      letter-spacing: 4px;
      margin-bottom: 10px;
      display: block;
      text-transform: uppercase;
    }

    h3 {
      font-size: 28px;
      line-height: 28px;
      padding-bottom: 15px;
      margin-bottom: 25px;
      text-transform: uppercase;
      border-bottom: 0.987617px dashed #ffffff;

      a {
        color: var(--global--color-gold);

        &:hover {
          color: var(--global--color-white);
        }
      }
    }
  }

  .name-tag {
    font-size: 18px;
    line-height: 31px;
    margin-top: 20px;
    letter-spacing: 1.5px;
  }

  .feature-item-details {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    flex-grow: initial;

    p {
      margin: 0 15px 0 0;
    }
  }

  .feature-img,
  .feature-img-wrapper {
    display: inline-block;
    height: 340px;
    width: 100%;
    background-repeat: no-repeat;
    background-color: gray;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    transition: 0.3s all;
  }

  .feature-img:hover {
    transform: scale(1.1);
    transition: 0.3s all;
  }

  &.active-info {
    display: flex;
  }
}

.related-product-title {
  font-size: 40px;
}

.collectible-mobile-title {
  display: none;
}

.athletes-template-default {
  .post-navigation,
  .post-thumbnail,
  .entry-header {
    display: none;
  }

  .athlete-banner {
    position: relative;
    height: 532px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 30%;
    background-position-x: 56%;

    .athlete-banner-info {
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      width: calc(100% - 80px);
      transform: translate(-50%, -50%);

      span {
        display: block;
        text-align: center;
        font-size: 17px;
        line-height: 29px;
        font-weight: 900;
        letter-spacing: 34px;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 88px;
        margin-bottom: 0;
        color: transparent;
        font-family: "AktivGrotesk Ex Black Italic";
        -webkit-text-stroke: 3px var(--global--color-white);
      }
    }
  }

  .athlete-facts-block {
    margin-top: 60px;
  }

  .athlete-facts {
    display: flex;
    margin: 0 -15px;
  }

  .facts-title {
    font-size: 33px;
    flex: 0 0 30%;
    max-width: 30%;
    padding: 0 15px;
  }

  .facts-details {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 70%;
    max-width: 70%;
    padding: 0 15px !important;
    margin: 0 -15px;

    li {
      list-style-type: none;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 30px;
      padding: 0 15px;
    }

    label {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
      font-family: "AktivGrotesk Ex Black Italic";
      text-transform: uppercase;
    }

    p {
      font-size: 12px;
    }
  }

  .athlete-collectible {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 120px;

    .collectible-img {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      border-bottom: 2px solid var(--global--color-white);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 72px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }
    }

    .collectible-details {
      display: flex;
      align-items: flex-end;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 140px;
    }

    .collectible-details-wrapper {
      max-width: 500px;

      h3 {
        font-size: 39px;
        line-height: 38px;
        letter-spacing: -2px;
        text-transform: uppercase;
        margin-bottom: 40px;
        color: var(--global--color-white);
      }

      p {
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 1.4px;
        margin-bottom: 50px;
      }

      .btn {
        font-size: 20px;
        line-height: 22px;
        padding: 13px 25px;
        color: var(--global--color-white);
        border-color: var(--global--color-white);
        font-family: "AktivGrotesk Ex Black Italic";

        &:hover {
          color: var(--global--color-black);
          background-color: var(--global--color-white);
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .athletes-template-default {
    .athlete-banner {
      .athlete-banner-info {
        span {
          letter-spacing: 30px;
        }

        h1 {
          font-size: 77px;
        }
      }
    }

    .facts-title {
      flex: 0 0 35%;
      max-width: 35%;
    }

    .facts-details {
      flex: 0 0 65%;
      max-width: 65%;
    }

    .athlete-collectible {
      .collectible-details {
        padding-left: 100px;
      }

      .collectible-details-wrapper {
        h3 {
          font-size: 34px;
          line-height: 35px;
          margin-bottom: 25px;
        }

        p {
          margin-bottom: 40px;
        }

        .btn {
          font-size: 16px;
          line-height: 22px;
          padding: 10px 24px;
        }
      }
    }
  }

  .filter-custom-content .feature-title-block h3 {
    font-size: 26px;
    line-height: 26px;
  }
}

@media screen and (max-width: 1199px) {

  .entry-title {
    font-size: 50px;
  }

  .filter-custom-content {
    .feature-title-block h3 {
      font-size: 22px;
      line-height: 22px;
    }

    .feature-item-details {
      display: block;

      .feature-btn {
        margin-top: 20px;
      }
    }
  }

  .filter-custom-content .name-tag {
    font-size: 16px;
  }

  .filter-custom-contents {
    margin-bottom: 60px;
  }

  .athletes-template-default {
    .facts-title {
      font-size: 27px;
      flex: 0 0 32%;
      max-width: 32%;
    }

    .facts-details {
      flex: 0 0 68%;
      max-width: 68%;
    }

    .athlete-banner .athlete-banner-info {
      width: calc(100% - 40px);

      h1 {
        font-size: 67px;
      }
    }

    .athlete-facts-block {
      margin-top: 40px;
    }

    .athlete-collectible {
      margin-bottom: 100px;

      .collectible-details {
        padding-left: 60px;
      }

      .collectible-details-wrapper {
        h3 {
          font-size: 32px;
          margin-bottom: 20px;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .entry-title {
    font-size: 40px;
  }

  .custom-post-with-filter {
    .filter-list {
      a {
        padding: 0 21px;
      }
    }
  }

  .filter-custom-content {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .related-product-title {
    font-size: 35px;
    margin-bottom: 50px;
  }

  .athletes-template-default {
    .athlete-collectible {
      display: block;
      margin-bottom: 80px;

      .collectible-img,
      .collectible-details {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .collectible-details {
        margin-top: 30px;
        padding-left: 0;

        .collectible-details-wrapper {
          max-width: 100%;
          text-align: center;
          width: 100%;
        }
      }
    }

    .athlete-banner {
      height: 400px;

      .athlete-banner-info {
        span {
          font-size: 15px;
          letter-spacing: 15px;
        }

        h1 {
          font-size: 48px;
        }
      }
    }

    .athlete-facts {
      display: block;
    }

    .facts-title,
    .facts-details {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .facts-title {
      font-size: 33px;
      margin-bottom: 25px;
    }

    .facts-details {
      margin: 0;
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .athletes-page .entry-title {
    max-width: 340px;
    margin: 0 auto;
  }

  .entry-title {
    font-size: 32px;
  }

  .filter-custom-content {
    flex: 0 0 100%;
    max-width: 100%;

    .feature-title-block span {
      font-size: 10px;
    }

    .feature-title-block h3 {
      font-size: 24px;
      line-height: 24px;
    }

    .name-tag {
      font-size: 14px;
      margin-top: 15px;
    }

    .feature-item-details {
      display: flex;
      align-items: center;

      p {
        max-width: 100%;
      }

      .feature-btn {
        margin-top: 0;
      }
    }

    .feature-img,
    .feature-img-wrapper {
      height: 250px;
    }

    .feature-btn {
      font-size: 10px;
      min-width: 95px;
    }
  }

  .custom-post-with-filter .filter-list {
    overflow-x: auto;
    margin-bottom: 50px;

    ul {
      min-width: 460px;
      margin-bottom: 0;
    }
  }

  .custom-post-with-filter {
    .filter-list {
      a {
        padding: 0;
      }
    }
  }

  .related-product-title {
    font-size: 30px;
  }

  .collectible-mobile-title {
    display: block;
    text-align: center;
    font-size: 30px;
    line-height: 28px;
    letter-spacing: -2px;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: var(--global--color-white);
  }

  .athlete-collectible-block {
    margin-top: 50px;
  }

  .athletes-template-default {
    .athlete-banner {
      height: 532px;

      .athlete-banner-info {
        h1 {
          font-size: 60px;
          -webkit-text-stroke: 2px var(--global--color-white);
        }

        span {
          font-size: 13px;
          line-height: 22px;
          letter-spacing: 26px;
        }
      }
    }

    .facts-details {
      li {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .athlete-collectible {
      .collectible-details-wrapper {
        h3 {
          display: none;
        }

        p {
          margin-bottom: 25px;
        }

        .btn {
          font-size: 15px;
          padding: 8px 24px;
        }
      }
    }

    .facts-details li {
      &:nth-child(3) {
        order: 5;
        max-width: 100%;
        flex: 0 0 100%;
      }
      &:nth-child(4) {
        order: 2;
      }
      &:nth-child(5) {
        order: 4;
      }
      &:nth-child(6) {
        order: 6;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .athletes-template-default {
    .athlete-banner {
      height: 400px;

      .athlete-banner-info {
        h1 {
          font-size: 34px;
        }

        span {
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 6px;
        }
      }
    }

    .facts-title {
      font-size: 26px;
    }
  }

  .collectible-mobile-title {
    font-size: 22px;
    line-height: 24px;
  }
}

@media screen and (max-width: 374px) {
  .athletes-page .entry-title {
    font-size: 26px;
    max-width: 300px;
  }

  .filter-custom-content .feature-title-block h3 {
    font-size: 20px;
    line-height: 20px;
  }

  .filter-custom-contents {
    margin-bottom: 40px;
  }

  .related-product-title {
    font-size: 24px;
    margin-bottom: 35px;
  }

  .athletes-template-default .facts-details li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
