.hide-block {
  display: none !important;
}

.select2-search--hide {
  display: none !important;
}

.select2-dropdown {
  display: block;

  li {
    font-size: 14px;
    font-family: "AktivGrotesk Ex Black Italic";
    padding: 8px 0;
    margin: 0 15px;
    text-transform: uppercase;
    list-style-type: none;
    border-bottom: 1px solid var(--global--color-white);
    color: transparent;
    -webkit-text-stroke: 1px var(--global--color-white);

    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:last-child {
      border-bottom: none;
    }
  }
}

.select2.select2-container--default {
  background: var(--global--color-black) !important;
}

.select2.select2-container--default.select2-container--open {
  z-index: 5 !important;

  &::after {
    content: "";
    position: absolute;
    justify-self: end;
    width: 16px;
    height: 9px;
    background-color: #fff;
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    z-index: 2;
    grid-area: select;
    right: 0;
    top: 54%;
    transform: translate(-14px, -50%);
    pointer-events: none;
  }
}

.select2-container--default.select2-container--open {
  z-index: 3;
}

.select2-container--open .select2-results__options {
  margin-top: -28px;
}

.select2-container--default .select2-results > .select2-results__options {
  background-color: var(--global--color-black);
  border: 1px solid var(--global--color-white);
  border-radius: 10px;
  max-height: 100%;
}

.select2-dropdown--below .select2-results > .select2-results__options {
  padding: 30px 0 5px !important;
  cursor: pointer;
}

.select2-container--default .select2-results__option--selected {
  color: var(--global--color-white);
  -webkit-text-stroke: 1px transparent;
  background-color: transparent;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  -webkit-text-stroke: 1px transparent;
  background-color: transparent;
}

.select2-container--default .select2-selection__rendered {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.entry-header {
  text-transform: uppercase;
}

.block-sub-title {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 7px;
}

.block-main-title {
  font-size: 46px;
  line-height: 45px;
}

.btn.btn-primary {
  font-size: 14px;
  padding: 7px 22px;
  display: inline-block;
  width: max-content;
  border-color: var(--global--color-white);
  color: var(--global--color-white);
  font-family: "AktivGrotesk Ex Black Italic";

  &:hover {
    background-color: var(--global--color-white);
    color: var(--global--color-black);
  }

  &.btn-square {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 19px 36px;
    border-radius: 0;
    color: var(--global--color-black);
    background-color: var(--global--color-gold);
    border: 2px solid var(--global--color-gold);

    &:hover {
      background-color: transparent;
      color: var(--global--color-gold);
    }
  }
}

.nav-prev-text,
.nav-next-text {
  display: none;
}

.pagination.navigation .page-numbers:not(.prev):not(.next) {
  &.current {
    text-decoration: none;
  }
}

.pagination.navigation {
  .nav-links {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .page-numbers {
    display: inline-flex;
    align-items: center;
    min-width: auto;

    &.dots {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .page-numbers:not(.prev):not(.next) {
    padding-top: 5px;
  }

  .prev,
  .next {
    height: 44px;
    width: 44px;
    background: #333;
    border-radius: 50%;
    justify-content: center;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &:hover {
      background-color: var(--global--color-white);
    }

    &:focus {
      outline: none;
      color: var(--pagination--color-link-hover);
    }

    svg {
      margin: 0;
      top: auto !important;
    }
  }

  .prev {
    position: absolute;
    left: 0;
  }

  .next {
    position: absolute;
    right: 0;
  }
}

.wpcf7-form:not(.invalid) {
  .wpcf7-response-output {
    border: none;
    text-align: center;
  }
}

.wpcf7-form {
  .wpcf7-response-output {
    text-align: center;
  }
}

.product-quantity-block {
  label {
    display: block;
    width: max-content;
    position: relative !important;
    color: var(--global--color-focus-tertiary);
    clip-path: unset;
    -webkit-clip-path: unset;
    margin-right: 15px;
    font-family: "AktivGrotesk Ex Black Italic";
    font-size: 12px;
    line-height: 20px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  .quantity-field {
    position: relative;
    display: inline-flex;
    padding: 0 20px;
    margin-right: 20px;
    border: 1px solid var(--global--color-focus-tertiary);
    border-radius: 35px;
    font-family: "AktivGrotesk Ex Black Italic";
    a {
      color: var(--global--color-focus-tertiary);
    }
    .plus-btn {
      font-size: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }

    .min-btn {
      font-size: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }

    .input-text {
      font-size: 20px;
      background-color: transparent;
      border: none;
      color: var(--global--color-focus-tertiary);
      text-align: center;
      padding: 11px 8px;
      width: 70px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      &:focus {
        text-decoration: none;
        outline: none;
      }
    }
  }
}

.container,
.footer-container,
.post-thumbnail,
.entry-content .wp-audio-shortcode,
.entry-content
  > *:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-separator):not(.woocommerce),
*[class*="inner-container"]
  > *:not(.entry-content):not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-separator):not(.woocommerce),
.default-max-width {
  max-width: 1700px;
}

@media only screen and (max-width: 1199px) {
  .page-template-default.singular .entry-header,
  .site-main .athletes-page div.entry-header {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .page-template-default.singular .entry-header,
  .site-main .athletes-page div.entry-header {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .select2.select2-container--default.select2-container--open::after,
  .product-template-default .product table.variations .value::after,
  .faq-select::after {
    width: 12px !important;
    height: 6px !important;
  }
}
